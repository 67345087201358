"use client";
import React, {useEffect, useState} from 'react';
import {useWeb3React} from "@web3-react/core";
import { useNavigate } from 'react-router-dom';
import ConnectWalletWithGoogle from "../web3/ConnectWalletWithGoogle";
import AppleLogin from 'react-apple-login';
import {jwtDecode} from "jwt-decode";
import {InjectedConnector} from "@web3-react/injected-connector";
import {WalletSelector} from "../web3/WalletSelector";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import PurchaseHistory from "../pages/PurchaseHistory";
import Modal from "react-modal";
const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42] });

interface ConnectWalletProps {
  isOpen: boolean;
  onClose: () => void;
  address: string | null | undefined;
}

const Header: React.FC = () => {
  const navigate = useNavigate();

  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const [isAppleSDKLoaded, setIsAppleSDKLoaded] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);



  const openHistory = () => setIsHistoryOpen(true);
  const closeHistory = () => setIsHistoryOpen(false);
  const openWallet = () => setIsWalletOpen(true);
  const closeWallet = () => setIsWalletOpen(false);

  const tokenValue = localStorage.getItem('account');
  const [token, setToken] = useState(false);
  const {connect, disconnect, account, connected, wallets} = useWallet();

  useEffect(() => {
    if (tokenValue === null) {
      setToken(false);
    } else {
      setToken(true);
    }
  }, [tokenValue]);

  console.log(account?.address);
  useEffect(() => {
    if (!account?.address) {
      console.log("No account found");
    }
  }, [account]);

  const scrollToSection = (sectionId: string) => {
    navigate('/'); // 홈 경로로 이동
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
    const checkAppleSDK = () => {
      if (window.AppleID) {
        setIsAppleSDKLoaded(true);
      } else {
        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.onload = () => setIsAppleSDKLoaded(true);
        script.onerror = () => console.error('Apple JS SDK 로드 실패');
        document.body.appendChild(script);
      }
    };
  };

  return (
    <header>
      <div className="logo">
        <a href="/" onClick={(e) => { e.preventDefault(); scrollToSection(''); }}>
          <img src="/image/pudgy_logo3.png" alt="Logo" />
        </a>
      </div>
      <nav className="gnb">
        <ul>
          <li>
            <a href="/#prologue" onClick={(e) => { e.preventDefault(); scrollToSection('prologue'); }}>
              PROLOGUE
            </a>
          </li>
          <li>
            <a href="/#land" onClick={(e) => { e.preventDefault(); scrollToSection('land'); }}>
              L.AND
            </a>
          </li>
          <li>
            <a href="/#shop" onClick={(e) => { e.preventDefault(); scrollToSection('shop'); }}>
              SHOP
            </a>
          </li>
          <li>
            <a href="/#project" onClick={(e) => { e.preventDefault(); scrollToSection('project'); }}>
              PROJECT
            </a>
          </li>
        </ul>
      </nav>
      {account?.address ? (
          <div className="logout">
            <button type="button" onClick={openHistory} className="mypage"></button>
            <button type="button" onClick={openWallet} className="logoutBtn"></button>
          </div>
      ) : (
          <div className="login">
            <WalletSelector/>
          </div>
      )}

      <ConnectWallet isOpen={isWalletOpen} onClose={closeWallet} address={account?.address}/>

      <Modal
          isOpen={isHistoryOpen}
          onRequestClose={closeHistory} // 배경 클릭이나 ESC 키로 모달 닫기
          contentLabel="Modal"
          style={{
            overlay: {
              overflow: 'auto',
              zIndex: '99999',
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
              height: '70%',
              zIndex: '1',
              width: '90%',
              background: 'rgba(30, 30, 30, 0.8)'
            },
          }}
      >
        <>
          <button className="m-close-btn" data-modal="termsofUseModal" id="closeModalButton"
                  onClick={() => {
                    closeHistory();
                  }}>
            <img src="/image/close_modal.png"/>
          </button>
          <PurchaseHistory/>
        </>

      </Modal>
    </header>
  );
};

const ConnectWallet: React.FC<ConnectWalletProps> = ({ isOpen, onClose, address }) => {
  const {disconnect} = useWallet();

  const handleDisconnect = () => {
    disconnect(); // disconnect 함수 호출로 지갑 연결 해제
    onClose();
  };


  return (
      <div className="header-modal-overlay">
        {isOpen && (
            <>
              <div onClick={onClose}></div>

              <div className="eventModal " tabIndex={-1} role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content min-h-auto bg_white posi-r"
                       style={{border: '3px solid #00142D'}}>
                    <button onClick={onClose} className="modal-close-btn2 w-ba">
                      <img src="/image/close.png" alt="Close"/>
                    </button>
                    <div className="wallbody loginPage logoutPage">
                      <div className="modal-body">
                        <center><img src="/image/logoutText.png" alt="Connect Wallet"/></center>
                        <p className="wall_ment outText">
                          Are you sure you want to log out?
                        </p>
                        <p className="wall_ment w_address">
                          <span>{address}</span>
                        </p>
                        <div className="wrap-btn outBtn">
                          <button onClick={onClose}>Cancel</button>
                          <button onClick={handleDisconnect}>Confirm</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </>

        )}
      </div>
  )
};

interface JwtPayload {
  sub: string;
  name: string;
  iat: number;
}

const AppleLoginButton = () => {
  const [userInfo, setUserInfo] = useState<any>(null);
  const [authToken, setAuthToken] = useState<any>(null);
  const {chainId, account, active, activate, deactivate,} = useWeb3React();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenValue, setTokenValue] = useState<string | null>('');
  const storedAccount = localStorage.getItem('account');
  const handleCallback = async (data: any) => {
    const {authorization, user} = data;
    console.log(data);

    if (!active) {
      try {
        activate(injected);
        // window.location.reload(); // 화면 새로고침
        console.log('Wallet activated:', active);
      } catch (error) {
        console.error('Error activating wallet:', error);
      }
    }


    if (account) {
      localStorage.setItem('account', account);
    }

    if (authorization) {
      const {code, id_token} = authorization;
      console.log("Authorization Code:", code);
      console.log("ID Token:", id_token);

      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');

      // ID Token 디코딩
      const decodedToken = jwtDecode(id_token);
      console.log("Decoded Token:", decodedToken);

      // Apple 고유 사용자 ID, 이메일 등 정보 추출
      const appleUserId = decodedToken?.sub;
      const email = decodedToken.nbf;

      localStorage.setItem('appleIdToken', id_token);

      if (typeof appleUserId === "string") {
        localStorage.setItem('appleUserId', appleUserId);
      }

      // localStorage.setItem('appleUserEmail', email);

      setAuthToken(id_token); // 상태 업데이트
      // setUserInfo({ userId: appleUserId, email }); // 상태 업데이트

    } else {
      console.error("Authorization 정보 없음");
    }
  };


  // 컴포넌트가 처음 렌더링 될 때, 로컬스토리지에서 유저 정보 불러오기
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const storedToken = localStorage.getItem('appleIdToken');
    const storedUserId = localStorage.getItem('apple_user_id');
    const storedAccount =localStorage.getItem('account');

    setTokenValue(storedAccount);

    if (storedLoginStatus === 'true' ) {
      setIsLoggedIn(true);
    }
    setAuthToken(storedToken);
  }, []);

  useEffect(() => {
    if (active && account) {
      localStorage.setItem('account', account);
    }
  }, [active, account]);


  const handleLogout = () => {
    setAuthToken(""); // 상태 초기화
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('appleIdToken');
    localStorage.removeItem('account');
    localStorage.removeItem('active');
    window.location.reload(); // 화면 새로고침
    console.log("Logged out");
  };

  return(
      <div className={`apple-login-container`}
           style={{display : tokenValue? 'none':''}}
      >
        {/* AppleLogin 컴포넌트를 authToken이 없을 때만 표시 */}
        {!isLoggedIn ? (
            <AppleLogin
                clientId="com.pudgy.loginIdentifier"
                redirectURI="https://l-and.shop/"
                scope="name email"
                responseType="code id_token"
                usePopup={true}
                callback={handleCallback}
            />) : null}
        {authToken ? (
            <div>
              <p style={{fontSize: "13px"}}>wallet : {tokenValue}</p>
              <button
                  onClick={handleLogout}
                  style={{
                    padding: '10px 20px',
                    fontSize: '12px',
                    backgroundColor: '#4285F4',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
              >
                Logout
              </button>
            </div>
        ) :null}
      </div>
  )
}

export default Header;
