// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#section00 {
  background-attachment: fixed;
  /*max-height: 740px;*/
  min-height: 980px;
  position: relative;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.video_bg {
  width: 110%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  min-height:740px
}
.video_bg iframe {
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video_bg .videoPlayer {
  width: 110%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video_bg::after {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/css/components/Section00.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,uCAAuC;EACvC,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,WAAW;EACX,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB;AACF;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;AACb","sourcesContent":["#section00 {\r\n  background-attachment: fixed;\r\n  /*max-height: 740px;*/\r\n  min-height: 980px;\r\n  position: relative;\r\n  transition: all .5s ease-in-out;\r\n  -webkit-transition: all .5s ease-in-out;\r\n  width: 100%;\r\n  overflow: hidden;\r\n  height: 100%;\r\n}\r\n.video_bg {\r\n  width: 110%;\r\n  height: 0;\r\n  padding-bottom: 56.25%;\r\n  position: relative;\r\n  min-height:740px\r\n}\r\n.video_bg iframe {\r\n  width: 110%;\r\n  height: 100%;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n}\r\n.video_bg .videoPlayer {\r\n  width: 110%;\r\n  height: 100%;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n}\r\n.video_bg::after {\r\n  content:'';\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  z-index: -1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
