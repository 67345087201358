import React, {useContext, useEffect, useState} from 'react';
import '../terms.css';
import {useNavigate} from "react-router-dom";

interface TermsProps {
    closeModal: () => void;
}
const TermsOfUse: React.FC<TermsProps> = ({ closeModal }) => {
    const navigate = useNavigate();
    return (
        <div className="termsofUseModal closeM modal fade"  role="dialog" aria-modal="true"
             style={{paddingRight: '17px' }}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content min-h-auto">
                    <button className="m-close-btn" data-modal="termsofUseModal" id="closeModalButton"  onClick={()=> {closeModal();}}>
                        <img src="/image/close_modal.png"/>
                    </button>
                    <div className="modal-panel w-100">
                        <div className="modal-body mt-md-4 mb-4 mt-2">
                            <div className="text-left mb-4">
                                <h4 className="mb-0 font-bold ">Terms of Use</h4>
                            </div>
                            <div className="form-container scrollable">
                                <p>Article 1 (Purpose)</p>
                                <p>The purpose of these Terms and Conditions is to stipulate the rights, obligations,
                                    and responsibilities of users who use the Internet-related services (hereinafter
                                    referred to as the “Service”) provided by the cyber mall (hereinafter referred to as
                                    the “Mall”) operated by Daehong Communications Company (e-commerce business), and
                                    the rights, obligations, and responsibilities of users who use the Service.</p>
                                <p>※「These Terms and Conditions shall also apply to e-commerce services using PC
                                    communication, wireless, etc. unless contrary to their nature.」</p>


                                <p>Article 2 (Definition)</p>
                                <p>① “Mall” refers to a virtual business place established by Daehong Communications to
                                    provide goods or services (hereinafter referred to as “Goods, etc.”) to users, and
                                    is also used to mean a business operator that operates a cyber mall.</p>
                                <p>② “User” refers to person who access the “Mall” and receive the services provided by
                                    the “Mall” in accordance with these Terms and Conditions.</p>


                                <p>Article 3 (Statement, Explanation and Amendment of Terms and Conditions)</p>
                                <p>① The “Mall” shall post the contents of these Terms and Conditions, the name of the
                                    company and representative, the address of the business location (including the
                                    address of the place where consumer complaints can be handled), telephone number,
                                    fax number, e-mail address, business license number, e-commerce business report
                                    number, and personal information protection officer on the initial service screen
                                    (front) of the Daehong Communications Cyber Mall so that users can easily recognize
                                    them. However, the contents of the Terms and Conditions may be viewed by the user
                                    through a connected screen.</p>
                                <p>② The “Mall” shall seek the user's confirmation by providing a separate connection
                                    screen or pop-up screen so that the user can understand important contents such as
                                    withdrawal, delivery responsibility, refund conditions, etc. among the contents
                                    stipulated in the Terms and Conditions before the user agrees to the Terms and
                                    Conditions.</p>
                                <p>③ The “Mall” may amend these Terms and Conditions to the extent that it does not
                                    violate the relevant laws, such as the “Act on Consumer Protection in Electronic
                                    Commerce, etc.”, “Act on Regulation of Terms and Conditions”, “Electronic Documents
                                    and Electronic Transactions Basic Act”, “Electronic Financial Transactions Act”,
                                    “Electronic Signature Act”, “Act on Promotion of Information and Communications
                                    Network Utilization and Information Protection”, “Act on Door-to-Door Sales”, and
                                    “Consumer Affairs Act”.</p>
                                <p>④ If the “Mall” revises the Terms and Conditions, it shall specify the effective date
                                    and reason for revision and notify the current Terms and Conditions on the
                                    initialization screen of the Mall from 7 days before the effective date to the day
                                    before the effective date. However, if the terms and conditions are changed to the
                                    disadvantage of the user, a grace period of at least 30 days shall be given in
                                    advance. In this case, the “Mall” shall clearly compare the contents before the
                                    revision and the contents after the revision and display them in a way that is easy
                                    for users to understand.</p>
                                <p>⑤ If the “Mall” revises the Terms and Conditions, the revised Terms and Conditions
                                    shall apply only to contracts entered into after the effective date, and the terms
                                    and conditions prior to the revision shall apply to contracts already entered into
                                    prior to the effective date. However, if a user who has already concluded a contract
                                    transmits to the 'Mall' the intention to be subject to the provisions of the revised
                                    Terms within the notice period of the revised Terms pursuant to Paragraph 3 and
                                    receives the 'Mall's consent, the provisions of the revised Terms shall apply.</p>
                                <p>⑥ Matters not specified in these Terms and the interpretation of these Terms shall be
                                    governed by the Act on Consumer Protection in Electronic Commerce, the Act on
                                    Regulation of Terms and Conditions, the Consumer Protection Guidelines for
                                    Electronic Commerce, etc. set forth by the Fair Trade Commission, and related laws
                                    and regulations or common practice.</p>


                                <p>Article 4 (Provision and Modification of Services)</p>
                                <p>① The “Mall” shall perform the following tasks.</p>

                                <p>1. provide information on goods or services and conclude a purchase contract
                                </p>
                                <p>2. delivery of goods or services for which a purchase contract has been
                                    concluded
                                </p>
                                <p>3. other tasks prescribed by the 'Mall'</p>

                                <p>The “Mall” may change the contents of the goods or services to be provided under a
                                    contract to be concluded in the future in the event that the goods or services are
                                    out of stock or the technical specifications are changed. In this case, the contents
                                    of the changed goods or services and the date of provision shall be specified and
                                    immediately notified on the place where the contents of the current goods or
                                    services are posted.</p>
                                <p>③ If the 'Mall' changes the contents of the service contracted with the user to
                                    provide due to reasons such as out of stock of goods or changes in technical
                                    specifications, the 'Mall' shall immediately notify the user of the reason to the
                                    address where the user can be notified.</p>
                                <p>④ In the case of the preceding Paragraph, the 'Mall' shall compensate the User for
                                    any damages incurred by the User. However, this shall not apply if the 'Mall' proves
                                    that it is not intentional or negligent.</p>


                                <p>Article 5 (Interruption of Service)</p>
                                <p>① The “Mall” may temporarily suspend the provision of the Service in the event of
                                    maintenance, inspection, replacement, or breakdown of information and communication
                                    facilities such as computers, interruption of communication, etc.</p>
                                <p>② The 'Mall' shall compensate for any damages incurred by the User or a third party
                                    due to the temporary suspension of the provision of the Service under Paragraph 1.
                                    However, this shall not apply if the 'Mall' proves that it is not intentional or
                                    negligent.</p>
                                <p>③ If the Service cannot be provided for reasons such as switching business items,
                                    abandonment of business, or integration between companies, the “Mall” shall notify
                                    the user in the manner prescribed in Article 6 and compensate the consumer in
                                    accordance with the conditions originally provided by the “Mall”.</p>


                                <p>Article 6 (Notification to User)</p>
                                <p>① When the 'Mall' notifies a user, it may do so to the e-mail address specified by
                                    the user in advance by agreement with the 'Mall'.</p>
                                <p>② In the case of notifications to unspecified users, the 'Mall' may replace
                                    individual notifications by posting them on the 'Mall' bulletin board for more than
                                    one week. However, individual notifications shall be made for matters that have a
                                    significant impact on the user's transactions.</p>


                                <p>Article 7 (Purchase Application)</p>
                                <p>① The user of the “Mall” shall apply for purchase by the following or similar methods
                                    on the “Mall”, and the “Mall” shall provide each of the following contents in an
                                    easy-to-understand manner when the user applies for purchase.</p>

                                <p>search and selection of goods, etc.</p>
                                <p>inputting the recipient's name, address and postal code, telephone number,
                                    e-mail address (or mobile phone number), etc.
                                </p>
                                <p>confirmation of the contents of the Terms and Conditions, the services subject
                                    to the right of withdrawal, and the cost burden such as shipping fees and
                                    installation fees
                                </p>
                                <p>indication (e.g., mouse click) of acceptance of these Terms and Conditions and
                                    confirmation or rejection of items 3.1 above
                                </p>
                                <p>application for purchase of goods, etc. and confirmation of the same or consent
                                    to the confirmation of the “Mall”
                                </p>
                                <p>selection of payment method</p>

                                <p>② If the “Mall” needs to provide or entrust the personal information of the Buyer to
                                    a third party, the Buyer's consent shall be obtained at the time of the actual
                                    purchase application. At this time, the “Mall” shall specify to the Buyer the items
                                    of personal information provided, the recipient, the purpose of using and retaining
                                    the personal information of the recipient, and the period of use. However, if there
                                    are other provisions in the relevant laws and regulations, such as in the case of
                                    entrusting personal information processing pursuant to Article 23 (1) of the Act on
                                    Promotion of Information and Communications Network Utilization and Information
                                    Protection, etc.</p>


                                <p>Article 8 (Formation of Contract)</p>
                                <p>① The “Mall” may not approve the purchase application as described in Article 7 if
                                    any of the following items apply. However, in the case of concluding a contract with
                                    a minor, the minor or his/her legal representative shall be notified that the
                                    contract may be canceled if the consent of the legal representative is not
                                    obtained.</p>

                                <p>if there are any falsehoods, omissions, or errors in the application contents
                                </p>
                                <p>if the minor purchases goods and services prohibited by the Youth Protection
                                    Act, such as tobacco, alcohol, etc.
                                </p>
                                <p>if the 'Mall' determines that approving other purchase applications would
                                    significantly impede the 'Mall' technology.
                                </p>

                                <p>② The contract shall be deemed to have been concluded when the 'Mall''s consent
                                    reaches the user in the form of a receipt confirmation notice in accordance with
                                    Article 10 (1).</p>
                                <p>③ The intention of the 'Mall' to accept the User's purchase application shall include
                                    confirmation of the User's purchase application and information regarding the
                                    availability of the product, cancellation of the correction of the purchase
                                    application, etc.</p>


                                <p>Article 9 (Payment Method)</p>
                                <p>Payment for goods or services purchased on the “Mall” may be made by any of the
                                    following methods. However, the “Mall” may not collect any nominal fees in addition
                                    to the price of goods or services for the user's payment method.</p>

                                <p>various account transfers such as phone banking, internet banking, mail banking,
                                    etc.
                                </p>
                                <p>Payment by various cards such as prepaid cards, debit cards, credit cards,
                                    etc.
                                </p>
                                <p>online direct deposit</p>
                                <p>payment by electronic money</p>
                                <p>Payment upon receipt</p>
                                <p>Payment by gift certificates contracted with the 'Mall' or recognized by the
                                    'Mall'
                                </p>
                                <p>Payment by other electronic payment methods, etc.</p>



                                <p>Article 10 (Notification of Receipt, Change and Cancellation of Purchase
                                    Application)</p>
                                <p>① The “Mall” shall send a receipt confirmation notice to the user when the user
                                    applies for purchase.</p>
                                <p>② If there is a discrepancy in the User's intention, the User may request to change
                                    or cancel the purchase application immediately after receiving the receipt of the
                                    receipt confirmation notice, and the “Mall” shall process the request without delay
                                    if there is a request from the User before delivery. However, if the payment has
                                    already been made, the provisions of Article 13 regarding withdrawal of
                                    subscription, etc. shall apply.</p>


                                <p>Article 11 (Supply of Goods, etc.)</p>
                                <p>① Unless there is a separate agreement with the User regarding the timing of the
                                    supply of Goods, the “Mall” shall take other necessary measures such as
                                    customization, packaging, etc. so that the Goods can be delivered within 7 days from
                                    the date the User makes a request. However, if the 'Mall' has already received all
                                    or part of the payment for the Goods, the 'Mall' shall take action within 3 business
                                    days from the date of receipt of all or part of the payment. At this time, the
                                    “Mall” shall take appropriate measures so that the user can check the procedure and
                                    progress of the supply of goods, etc.</p>
                                <p>② The “Mall” shall specify the delivery method, the person who bears the delivery
                                    cost for each method, and the delivery period for each method for the goods
                                    purchased by the user. If the 'Mall' exceeds the agreed delivery period, the 'Mall'
                                    shall compensate the User for any damages caused by the 'Mall'. However, this shall
                                    not apply if the 'Mall' proves that it is not intentional or negligent.</p>


                                <p>Article 12 (Refund)</p>
                                <p>The “Mall” shall notify the user without delay if the goods applied for purchase by
                                    the user cannot be delivered or provided due to reasons such as out of stock, and if
                                    the user has received the payment for the goods in advance, the “Mall” shall refund
                                    the payment within 3 business days from the date of receipt or take necessary
                                    measures to refund the payment.</p>


                                <p>Article 13 (Cancellation of Purchase, etc.)</p>
                                <p>① A user who has concluded a contract for the purchase of goods with the “Mall” may
                                    withdraw his/her subscription within 7 days from the date of receipt of a written
                                    document regarding the contents of the contract pursuant to Article 13, Paragraph 2
                                    of the Act on Consumer Protection in Electronic Commerce (if the supply of goods is
                                    later than the date of receipt of the written document, it refers to the date of
                                    receipt of the goods or the start of the supply of goods). However, if the Act on
                                    Consumer Protection in Electronic Commerce, etc. provides otherwise regarding the
                                    withdrawal of a subscription, the provisions of the Act shall apply.</p>
                                <p>② The User shall not be entitled to return or exchange the Goods if the User has
                                    received the Goods in any of the following cases.</p>

                                <p>1. If the goods, etc. are lost or damaged due to reasons responsible to the user
                                    (however, if the packaging, etc. is damaged in order to check the contents of
                                    the goods, etc.)
                                </p>
                                <p>2. If the value of the Goods, etc. is significantly reduced due to use or
                                    partial consumption by the User
                                </p>
                                <p>3. If the value of the Goods has decreased significantly over time to the extent
                                    that it is difficult to resell the Goods
                                </p>
                                <p>4. If the packaging of the original Goods, etc. is damaged if it can be
                                    duplicated with Goods, etc. with the same performance.
                                </p>

                                <p>③ In the case of Paragraph 2, Items 2 through 4, the User's right to withdraw the
                                    subscription shall not be restricted if the “Mall” does not take measures such as
                                    specifying the fact that the withdrawal of the subscription is restricted in advance
                                    in a place where the consumer can easily recognize it or providing a trial
                                    product.</p>
                                <p>④ Notwithstanding the provisions of Paragraphs 1 and 2, if the contents of the Goods
                                    differ from the display or advertisement or are performed differently from the
                                    contract, the User may withdraw the Goods within 3 months from the date of receipt
                                    of the Goods and within 30 days from the date the User knew or could have known the
                                    fact.</p>


                                <p>Article 14 (Effect of withdrawal of subscription, etc.)</p>
                                <p>① The “Mall” shall refund the price of the goods, etc. already paid within 3 business
                                    days if the goods, etc. are returned from the user. In this case, if the 'Mall'
                                    delays in refunding the goods to the user, the 'Mall' shall pay the delayed interest
                                    calculated by multiplying the delayed interest rate (bracketed part deleted)
                                    specified in Article 21.2 of the Enforcement Decree of the Act on Consumer
                                    Protection in Electronic Commerce, etc. for the delayed period.</p>
                                <p>② In refunding the above payment, the “Mall” shall request the business that provided
                                    the payment method to suspend or cancel the charge for the goods or services without
                                    delay if the user paid for the goods or services by a payment method such as credit
                                    card or electronic money.</p>
                                <p>③ In the case of withdrawal of subscription, the user shall bear the cost of
                                    returning the supplied goods, etc. “The 'Mall' shall not charge any penalty or
                                    damages to the user for withdrawing the subscription, etc. However, if the contents
                                    of the goods, etc. are different from the contents of the display or advertisement
                                    or are performed differently from the contract, the 'Mall' shall bear the cost of
                                    returning the goods, etc.</p>
                                <p>④ If the user bears the shipping cost when receiving the goods, etc., the “Mall”
                                    shall clearly indicate who bears the cost when canceling the subscription so that
                                    the user can easily understand.</p>


                                <p>Article 15 (Personal Information Protection)</p>
                                <p>① When collecting personal information of users, the “Mall” collects the minimum
                                    amount of personal information to the extent necessary to provide services.</p>
                                <p>② The “Mall” shall notify the user of the purpose of collecting and using the user's
                                    personal information and obtain the user's consent.</p>
                                <p>③ The “Mall” shall not use the collected personal information for any purpose other
                                    than the purpose, and if a new purpose of use occurs or if it is provided to a third
                                    party, the “Mall” shall notify the user of the purpose and obtain the user's consent
                                    at the stage of use and provision. However, exceptions shall be made if otherwise
                                    stipulated in the relevant laws and regulations.</p>
                                <p>④ If the “Mall” is required to obtain the consent of the user pursuant to Paragraphs
                                    2 and 3, the contents on the Article 22 Paragraphs 2 such as the identity of the
                                    person in charge of personal information protection (affiliation, name and phone
                                    number, and other contact information), the purpose of collecting and using the
                                    information, and matters related to the provision of information to a third party
                                    (recipient, purpose of provision, and content of information to be provided) shall
                                    be specified or notified in advance, and the user may withdraw this consent at any
                                    time.</p>
                                <p>⑤ The user may request to view and correct errors in his/her personal information
                                    held by the 'Mall' at any time, and the 'Mall' shall be obligated to take necessary
                                    measures without delay. If the user requests the correction of an error, the 'Mall'
                                    shall not use the personal information until the error is corrected.</p>
                                <p>⑥ The “Mall” shall limit the number of persons who process the User's personal
                                    information to a minimum to protect personal information and shall be liable for any
                                    damages caused by the loss, theft, leakage, provision to a third party without
                                    consent, falsification, etc. of the User's personal information, including credit
                                    card, bank account, etc.</p>
                                <p>⑦ The “Mall” or a third party that has received personal information from the “Mall”
                                    shall destroy the personal information without delay when the purpose of collecting
                                    personal information or the purpose for which it was provided has been
                                    fulfilled.</p>
                                <p>⑧ The “Mall” does not set the consent box for the collection, use, and provision of
                                    personal information to be selected in advance. In addition, the “Mall” specifically
                                    specifies the services that are restricted when the user refuses to consent to the
                                    collection, use, and provision of personal information, and does not restrict or
                                    refuse to provide services due to the user's refusal to consent to the collection,
                                    use, and provision of personal information that is not a mandatory collection
                                    item.</p>


                                <p>Article 16 (Obligations of the 'Mall')</p>
                                <p>① The “Mall” shall not engage in any act prohibited by laws and these Terms and
                                    Conditions or contrary to public order and morals, and shall do its best to provide
                                    goods and services continuously and stably in accordance with these Terms and
                                    Conditions.</p>
                                <p>② The “Mall” shall have a security system to protect the personal information
                                    (including credit information) of users so that users can use the Internet service
                                    safely.</p>
                                <p>③ The 'Mall' shall be responsible for compensating the user for any damages caused by
                                    the unfair display or advertisement of goods or services in accordance with Article
                                    3 of the Act on Fairness of Display and Advertising.</p>
                                <p>④ The “Mall” does not send commercial e-mails for commercial purposes that the user
                                    does not want.</p>


                                <p>Article 17 (Obligations of Users)</p>
                                <p>The User shall not engage in the following acts:</p>

                                <p>1. register false information when applying or changing</p>
                                <p>2. steal information of others</p>
                                <p>3. altering information posted on the 'Mall'</p>
                                <p>4. transmitting or posting information (computer programs, etc.) other than the
                                    information specified by the “Mall”
                                </p>
                                <p>5. Infringement of intellectual property rights such as copyright of the 'Mall'
                                    or other third parties
                                </p>
                                <p>6. Acts that damage the reputation or interfere with the business of the 'Mall'
                                    or other third parties
                                </p>
                                <p>7. disclosing or posting obscene or violent messages, images, voices, or other
                                    information contrary to public order and morals on the Mall
                                </p>



                                <p>Article 18 (Relationship between the Connecting “Mall” and the Subordinate
                                    “Mall”)</p>

                                <p>① If the parent “mall” and the subordinate “mall” are connected by hyperlinks
                                    (e.g., the objects of hyperlinks include text, pictures, and fairy tale images),
                                    the former is called the connecting “mall” (website) and the latter is called
                                    the subordinate “mall” (website).
                                </p>
                                <p>② If the connecting “mall” expressly states on the initial screen of the
                                    connecting “mall” or on a pop-up screen at the time of connection that it is not
                                    responsible for the transaction with the user based on the goods provided by the
                                    connecting “mall” independently, it shall not be responsible for the
                                    transaction.
                                </p>


                                <p>Article 19 (Attribution of Copyrights and Restrictions on Use)</p>

                                <p>① Copyright and other intellectual property rights for works created by the
                                    'Mall' shall belong to the 'Mall'.
                                </p>
                                <p>② The User shall not use the information obtained through the use of the 'Mall'
                                    to which the intellectual property rights belong to the 'Mall' for commercial
                                    purposes by reproduction, transmission, publication, distribution, broadcasting,
                                    or other methods without the prior consent of the 'Mall' or cause a third party
                                    to use it.
                                </p>
                                <p>③ The 'Mall' shall notify the user if the copyright belonging to the user is
                                    used in accordance with the agreement.
                                </p>



                                <p>Article 20 (Dispute Resolution)</p>

                                <p>① The “Mall” shall establish and operate a damage compensation mechanism to
                                    reflect legitimate opinions or complaints raised by users and to compensate for
                                    the damage.
                                </p>
                                <p>② The “Mall” shall prioritize the handling of complaints and opinions submitted
                                    by users. However, if it is difficult to process promptly, the user shall be
                                    notified of the reason and the processing schedule immediately.
                                </p>
                                <p>③ If a user applies for damage relief in connection with an e-commerce dispute
                                    between the “Mall” and the user, the dispute may be subject to mediation by the
                                    Fair Trade Commission or a dispute mediation organization commissioned by the
                                    city/provincial governor.
                                </p>

                                <p>Article 21 (Jurisdiction and Governing Law)</p>

                                <p>① Lawsuits regarding e-commerce disputes between the “Mall” and the user shall
                                    be subject to the exclusive jurisdiction of the district court having
                                    jurisdiction over the user's address at the time of filing, or, if there is no
                                    address, the district court having jurisdiction over the user's residence.
                                    However, if the address or residence of the user is not clear at the time of
                                    filing, or if the user is a foreign resident, the case shall be filed with the
                                    competent court under the Civil Procedure Act.
                                </p>
                                <p>② E-commerce litigation between the “Mall” and the user shall be governed by the
                                    laws of Korea.
                                </p>


                                <p>Announcement Date: December 23, 2024</p>
                                <p>Effective Date: December 23, 2024</p>


                                <div className="form-footer flex-wrap mt-4">
                                    <button type="button"
                                            className="walbtn w-30"
                                            id="closeTermsButton"
                                            style={{borderRadius: '30px'}}
                                            onClick={() => {
                                                closeModal();
                                            }}
                                    >Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;

// 빈 export 구문 추가
export {};
