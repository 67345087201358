import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSub from '../components/HeaderSub';
import Section05 from '../components/Section05';
import '../App.css';
import { ApiContext } from '../App';

interface ProductData {
  seqNo: number;
  productName: string;
  category: string;
  description: string;
  content: string;
  botContent: string;
  retailPrice: number;
  soldPrice: number;
  productStock: number;
  imageFilePath: string;
  subImg1Path: string;
  subImg2Path: string;
  subImg3Path: string;
  subImg4Path: string;
  subImg5Path: string;
}

const ProductDetail: React.FC = () => {
  const apiUrl = useContext(ApiContext);
  const { seqNo } = useParams<{ seqNo: string }>();
  const [productData, setProductData] = useState<ProductData | null>(null);
  const containerRef = useRef<HTMLDivElement>(null); // container 요소 참조 생성

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/product/detail/${seqNo}`);
        const result = await response.json();

        if (result.code == 200) {
          setProductData(result.data);
        } else {
          console.error('Error fetching product data:', result.message);
        }
      } catch (error) {
        console.error('Failed to fetch product data:', error);
      }
    };

    fetchProductData();
  }, [seqNo]);

  // 마우스 휠 스크롤 이벤트 추가
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const containerElement = containerRef.current;
      if (containerElement) {
        // scrollTop 값을 deltaY만큼 증가시킵니다.
        containerElement.scrollTop += event.deltaY;
      }
    };

    // containerRef가 null이 아닌 경우에만 이벤트 리스너를 추가
    const containerElement = containerRef.current;
    containerElement?.addEventListener('wheel', handleWheel);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      containerElement?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="product-detail" ref={containerRef} style={{ overflow: 'auto', height: '100vh' }}>
      <HeaderSub />
      {/* <div className="wallet">
        <button className="event-link">
          <img src="/image/wallet.png" alt="Wallet" />
        </button>
      </div> */}

      {/* Belly Yellow Image Section */}
      <div className="belly-Y">
        <div className="belly-yellow">
          <img src="/image/bellyYellow.png" alt="Belly Yellow" />
        </div>
        <div className="belly-yellow2">
          <img src="/image/bellyYellow2.png" alt="Belly Yellow 2" />

          <div className="product-info">
            {productData ? (
              <>
                <h1>{productData.productName}</h1>
                <p><strong>Category:</strong> {productData.category}</p>
                <p><strong>Retail Price:</strong> {productData.retailPrice} KRW</p>
                <p><strong>Sold Price:</strong> {productData.soldPrice} KRW</p>
                <p><strong>Stock:</strong> {productData.productStock}</p>
                <p><strong>Description:</strong> {productData.description}</p>
                <div dangerouslySetInnerHTML={{ __html: productData.content }} />

                <div className="product-images">
                  <img src={productData.imageFilePath} alt={productData.productName} />
                  {productData.subImg1Path && <img src={productData.subImg1Path} alt="Sub Image 1" />}
                  {productData.subImg2Path && <img src={productData.subImg2Path} alt="Sub Image 2" />}
                  {productData.subImg3Path && <img src={productData.subImg3Path} alt="Sub Image 3" />}
                  {productData.subImg4Path && <img src={productData.subImg4Path} alt="Sub Image 4" />}
                  {productData.subImg5Path && <img src={productData.subImg5Path} alt="Sub Image 5" />}
                </div>

                <div dangerouslySetInnerHTML={{ __html: productData.botContent }} />
              </>
            ) : (
              <p>Loading product details...</p>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Section05/>
    </div>
  );
};

export default ProductDetail;
