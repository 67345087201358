import React from 'react';
import '../css/components/Modal.css';


interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="loginModal modal fade show" tabIndex={-1} role="dialog" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content min-h-auto">
            <button className="modal-close-btn w-ba none_bg" onClick={onClose}></button>
            <div className="modal-panel w-100">
              <div className="modal-body mt-md-4 mb-4 mt-2">
                <div className="text-left mb-4">
                  <h4 className="mb-0 font-bold">LOGIN</h4>
                  <span><i className="fas fa-exclamation-circle"></i> 회원정보를 입력하세요</span>
                </div>
                <div className="form-container">
                  {/* ID and Password Fields */}
                  <div className="form-group w-icon">
                    <div className="input-container">
                      <input type="text" placeholder="아이디" name="mb_login_id" autoComplete="off" />
                    </div>
                  </div>
                  <div className="form-group w-icon">
                    <div className="input-container">
                      <input type="password" placeholder="비밀번호" name="password" autoComplete="off" />
                    </div>
                  </div>
                  {/* Footer Buttons */}
                  <div className="form-footer flex-wrap mt-4">
                    <button type="submit" className="walbtn login-btn w-30">로그인</button>
                    <button type="button" className="walbtn join-link w-30">회원가입</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
