"use client";
import React, {createContext, useState, useEffect, useRef, useContext} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import { FullPage, Slide } from 'react-full-page';
// @ts-ignore
import {FullPage, Slide} from 'react-full-page';


import Header from './components/Header';
import Wallet from './components/Wallet';
import Section00 from './components/Section00';
import Section01 from './components/Section01';
import SectionLand from './components/SectionLand';
import Section02 from './components/Section02';
import Section03 from './components/Section03';
import Section04 from './components/Section04';
import Section05 from './components/Section05';
import LoginModal from './components/LoginModal';
import JoinModal from './components/JoinModal';
import Merge from './pages/Merge';
import ProductDetail from './pages/ProductDetail';
import ProductBuy from './pages/ProductBuy';
import EventDetail from './pages/EventDetail';
import Payment from "./pages/Payment";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFail from "./pages/PaymentFail";
import TradeRegistration from './pages/TradeRegistration';
import PurchaseHistory from "./pages/PurchaseHistory";


// import './App.css';
import './css/components/Modal.css';
// API URL 설정

//export const ApiContext = createContext<string>('https://testapi.baekhoeleven.com');
export const ApiContext = createContext<string>('https://api.l-and.shop');

const App: React.FC = () => {
    const apiUrl = useContext(ApiContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const [activeSection, setActiveSection] = useState<number>(0);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState<boolean>(false);

    const [bg_img1, setBgimg1] = useState<string>('');
    const [bg_img2, setBgimg2] = useState<string>('');
    const [bg_img3, setBgimg3] = useState<string>('');
    const [bg_img4, setBgimg4] = useState<string>('');

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const sections = [
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null),
        useRef<HTMLElement>(null)
    ];

    useEffect(() => {
        document.body.style.backgroundImage = `url(${backgroundImage})`;
        document.body.style.backgroundSize = '100% 100%';

        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
        };
    }, [backgroundImage]);

    //
    // useEffect(() => {
    //     const handleScroll = (event: WheelEvent) => {
    //         event.preventDefault();
    //         const direction = event.deltaY > 0 ? 1 : -1;
    //         setActiveSection(prev => Math.min(Math.max(prev + direction, 0), sections.length - 1));
    //     };
    //
    //     window.addEventListener('wheel', handleScroll, {passive: false});
    //
    //     return () => {
    //         window.removeEventListener('wheel', handleScroll);
    //     };
    // }, [sections.length]);
    //
    // useEffect(() => {
    //     const currentSection = sections[activeSection]?.current;
    //
    //     if (currentSection) {
    //         currentSection.scrollIntoView({behavior: 'smooth'});
    //     } else {
    //         console.error(`Section at index ${activeSection} not found.`);
    //     }
    // }, [activeSection, sections]);

    const setHeightAuto = () => {
        const lastSection = document.querySelector('.lastSection') as HTMLElement;
        if (lastSection) {
            lastSection.style.height = 'auto';
            lastSection.style.bottom = '0px';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isNowMobile = window.innerWidth <= 780;

            if (isNowMobile !== isMobile) {
                // 모바일/PC 상태가 변경될 때 API 호출
                setIsMobile(isNowMobile);
            }
        };

        const callApi = async ({deviceType}: { deviceType: any }) => {
            // 실제 API 호출 로직을 여기에 작성하세요
            // 예: fetch('/api/endpoint', { method: 'POST', body: JSON.stringify({ deviceType }) });
            try {
                const endpoint = deviceType === 'Mobile'
                    ? `${apiUrl}/api/main/thumbnail/mobile`
                    : `${apiUrl}/api/main/thumbnail/pc`;

                const response = await fetch(endpoint);

                if (!response.ok) {
                    console.error('Network error:', response.statusText);
                    return;
                }

                const result = await response.json();

                // setBgimg1(result.data.introMainImage);
                setBgimg2(result.data.backgroundImage);
                setBgimg3(result.data.backgroundImage);
                // setBgimg4(result.data.mergeImagePath);

            } catch (error) {
                console.error('Error fetching thumbnail images:', error);
            }
        };

        // 초기 상태에 따라 API 호출
        callApi({deviceType: isMobile ? "Mobile" : "PC"});

        window.addEventListener("resize", handleResize);

        // cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);


    const HomePage = () => (
        <div className="App" onLoad={() => {
            setHeightAuto();
        }}>


            {/*<section ref={sections[0]}><Section00/></section>*/}
            {/*<section ref={sections[1]}><Section01 onBackgroundChange={setBackgroundImage}/></section>*/}
            {/*<section ref={sections[2]}><SectionLand/></section>*/}
            {/*<section ref={sections[3]}><Section02/></section>*/}
            {/*<section ref={sections[4]}><Section04/></section>*/}
            {/*<section ref={sections[5]}><Section05/></section>*/}
            <FullPage>
                <Header/>
                <Slide><Section00 /></Slide>
                <Slide><Section01 onBackgroundChange={setBackgroundImage}/></Slide>
                <Slide><SectionLand bg_img3={bg_img3}/></Slide>
                <Slide><Section02 bg_img2={bg_img2}/></Slide>
                <Slide><Section04 /></Slide>
                <Slide className={"lastSection"}><Section05/></Slide>
            </FullPage>


            {/* 모달 상태에 따라 표시 */}
            <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>
            <JoinModal isOpen={isJoinModalOpen} onClose={() => setIsJoinModalOpen(false)}/>


        </div>
    );
    return (


        <ApiContext.Provider value="https://api.l-and.shop">
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/merge" element={<Merge/>}/>
                    <Route path="/pages/ProductDetail/:seqNo" element={<ProductDetail/>}/>
                    <Route path="/pages/ProductBuy/:seqNo" element={<ProductBuy/>}/>
                    <Route path="/pages/EventDetail/:seqNo" element={<EventDetail/>}/>
                    <Route path="/pages/Payment" element={<Payment/>}/>
                    <Route path="/pages/TradeReg" element={<TradeRegistration/>}/>
                    <Route path="/pages/success" element={<PaymentSuccess/>}/>
                    <Route path="/pages/fail" element={<PaymentFail/>}/>
                </Routes>
            </Router>
        </ApiContext.Provider>


    );
};

export default App;
