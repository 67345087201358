import React, { useState, useEffect, useContext, useRef } from 'react';
import { ApiContext } from '../App';
import { useNavigate } from 'react-router-dom';
import '../css/components/Section04.css';

import { useLocation } from 'react-router-dom';

interface isPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const Section04: React.FC = () => {
  const apiUrl = useContext(ApiContext);
  const [bgImage, setBgImage] = useState<string>(() => localStorage.getItem('mergeImagePath') || '');
  const sectionRef = useRef<HTMLElement>(null); // Ref for the section element
  
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const isOpenPopup = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // navigate('/merge');
    isOpenPopup();
  };

  // useEffect(() => {
  //   const fetchBackgroundImage = async () => {
  //     try {
  //       const response = await fetch(`${apiUrl}/api/main/thumbnail/pc`);
  //       if (!response.ok) {
  //         console.error('Network response was not ok:', response.statusText);
  //         return;
  //       }
  //
  //       const result = await response.json();
  //       if (result.code === 200 && result.data) {
  //         const image = new Image();
  //         image.src = result.data.mergeImagePath;
  //         console.log('mergeImagePath',result.data.mergeImagePath);
  //         // 이미지 로드 후 상태 업데이트
  //         image.onload = () => {
  //           setBgImage(result.data.mergeImagePath);
  //           localStorage.setItem('mergeImagePath', result.data.mergeImagePath);
  //
  //           // Set background with !important using style property
  //           if (sectionRef.current) {
  //             sectionRef.current.style.setProperty(
  //               'background-image',
  //               `url(${result.data.mergeImagePath})`,
  //               'important'
  //             );
  //             sectionRef.current.style.setProperty('background-size', 'cover', 'important');
  //             sectionRef.current.style.setProperty('background-position', 'center', 'important');
  //           }
  //         };
  //       } else {
  //         console.error('Failed to fetch thumbnail image:', result.message);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching thumbnail image:', error);
  //     }
  //   };
  //
  //   // fetchBackgroundImage();
  // }, [apiUrl,bgImage]);

  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth <= 780;

      if (isNowMobile !== isMobile) {
        // 모바일/PC 상태가 변경될 때 API 호출
        setIsMobile(isNowMobile);
      }
    };

    const callApi = async ({deviceType}: { deviceType: any }) => {
      try {
        const endpoint = deviceType === 'Mobile'
            ? `${apiUrl}/api/main/thumbnail/mobile`
            : `${apiUrl}/api/main/thumbnail/pc`;

        const response = await fetch(endpoint);

        if (!response.ok) {
          console.error('Network error:', response.statusText);
          return;
        }

        const result = await response.json();

        if (result.code === 200 && result.data) {
          const image = new Image();
          image.src = result.data.mergeImagePath;
          // 이미지 로드 후 상태 업데이트
          image.onload = () => {
            setBgImage(result.data.mergeImagePath);
            localStorage.setItem('mergeImagePath', result.data.mergeImagePath);

            // Set background with !important using style property
            if (sectionRef.current) {
              sectionRef.current.style.setProperty(
                  'background-image',
                  `url(${result.data.mergeImagePath})`,
                  'important'
              );
              sectionRef.current.style.setProperty('background-size', 'cover', 'important');
              sectionRef.current.style.setProperty('background-position', 'center', 'important');
            }
          };
        } else {
          console.error('Failed to fetch thumbnail image:', result.message);
        }

      } catch (error) {
        console.error('Error fetching thumbnail images:', error);
      }
    };

    // 초기 상태에 따라 API 호출
    callApi({deviceType: isMobile ? "Mobile" : "PC"});

    window.addEventListener("resize", handleResize);

    // cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.hash);
  // 쿼리 파라미터 값 추출
  const success = queryParams.get('success');
  const reqData = queryParams.get('req_data');
  const resData = queryParams.get('res_data');
  const data = queryParams.get('data');

  // JSON 형식으로 변환
  let parsedReqData = null;
  if (reqData) {
    try {
      parsedReqData = JSON.parse(decodeURIComponent(reqData));
    } catch (error) {
      console.error('Error parsing req_data:', error);
    }
  }

  return (
    <section id="section04" ref={sectionRef} style={{height: '100%'}}>
      <article id="project">
        <div className="merge-section">
          {/*<div className="pugy_text">*/}
          {/*  <div className="puzy_icon">*/}
          {/*    <ul>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*      <li> </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="btn_list">*/}
          {/*  <button onClick={handleButtonClick} className="merge-button">*/}
          {/*    Merge Page*/}
          {/*    <img src="/image/mergePage.svg" alt="Merge Page" />*/}
          {/*    */}
          {/*  </button>*/}
          {/*</div>*/}
          <div className="btn_list">
            <button className="merges-btn" onClick={handleButtonClick}>
              <img src="/image/mergepage_btn.png" alt="Merge Page"/></button>
          </div>
          {isOpen && <ConnectPopup isOpen={isOpen} onClose={closeModal} />}
        </div>
      </article>
    </section>
  );
};

const ConnectPopup: React.FC<isPopupProps> = ({ isOpen, onClose }) => {
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   navigate('/merge');
  // };

  return(
    <>
    <div className="modal-overlay"></div>

    <div className="section4-Modal" tabIndex={-1} role="dialog" aria-modal="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content min-h-auto bg_white" style={{ border: '3px solid #00142D' }}>
          <button className="modal-close-btn2 w-ba">
            <img src="/image/close.png" alt="Close" onClick={onClose}/>
          </button>
          <div className="wallbody mergeModal">
            <div className="modal-body">
              <center><img src="/image/Resort_ice_cream.png" alt="Connect Wallet" className='iceCream' /></center>
              <p className="wall_ment">
                Merge is being prepared.
                <br/>
                please wait for a moment.
              </p>
            <button className='modal-btn btn_check' onClick={onClose}
            // onClick={handleButtonClick} 
              style={{  width:'125px', 
                      height:'58px',
                      borderRadius:'15px',
                      border:'2px solid #00142d',
                      backgroundColor:'#477DFD',
                      color:'white',
                      fontSize:'16px',
                      boxShadow:'7px 8px 0px rgba(0, 0, 0, 1.2)'}}
              >
              <img className='imgCheck' src='/image/check_2.png' style={{display:'flex', marginLeft: '60px'}}/>

            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Section04;
