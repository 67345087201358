import React, {useContext, useEffect, useState} from 'react';
import '../payment.css';
import {useNavigate} from "react-router-dom";
const PaymentSuccess: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="p-bg">
            <div className="payment">
                <div className="p-wrap success">
                    <img src="/image/success.svg" alt="success" />
                        <p>Payment Success</p>
                        <p>Your Payment was successful!</p>
                        <div className="btn_container">
                            <button onClick={() => {
                                navigate("/")
                            }}>Back to Main</button>
                            {/*<button >Go to Mypage</button>*/}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;

// 빈 export 구문 추가
export {};
