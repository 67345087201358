import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../App';

interface Slide {
  vType: string;
  videoSrc: string;
  imgSrc: string;
  title: string;
  link: string;
  footerClass: string;
  footerText: string;
  buttonImg: string;
  buttonImgPath: string;
}
interface SectionLandProps {
  bg_img3: String;
}
const SectionLand: React.FC<SectionLandProps> = ({bg_img3}) => {
  const apiUrl = useContext(ApiContext);
  const [slides, setSlides] = useState<Slide[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/land/list`);
        const result = await response.json();

        if (result.code == 200) {
          const fetchedSlides = result.data.map((item: any) => ({
            vType: item.detailImagePath ? 'image' : 'video',
            videoSrc: item.detailImagePath || '/video/bellyVideo.gif',
            imgSrc: item.backgroundImgPath,
            title: item.title,
            link: item.link,
            footerClass: 'green_bg',
            footerText: 'A calm, relaxing resort where you can unwind overlooking the ocean in Bellyland.',
            buttonImg: '/image/green_btn.png',
            buttonImgPath : item.buttonImgPath
          }));

          setSlides(fetchedSlides);
        } else {
          console.error('Error fetching data:', result.code);
        }
      } catch (error) {
        console.error('Failed to fetch slides:', error);
      }
    };

    fetchSlides();
  }, [apiUrl]);

  const totalSlides = slides.length;

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  return (
    <section id="land" style={{backgroundImage : `url(${bg_img3})`}}>
      <div className="modal-overlay"></div>
      <div className="areaModal modal" role="dialog" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content min-h-auto none_bg">
            <button className="modal-close-btn w-ba mt100">
              <img src="/image/black_X.png" alt="Close" />
            </button>
            <div className="modal-panel w-100">
              <div className="slide-container">
                {slides.map((slide, index) => (
                  <div
                    className={`slide ${currentSlide === index ? 'active' : ''}`}
                    key={index}
                  >
                    <div className="modal-body">
                      <div className="p_area">
                        <div className="cristal">
                          <img src={slide.videoSrc} alt={slide.title} width="440px" height="440px" />
                        </div>
                        <div
                          className={`bg b0${index + 1}`}
                          style={{
                            backgroundImage: `url(${slide.imgSrc})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                        <div className="cha_ico">
                          <img
                            src={`/image/${index + 1 < 10 ? `0${index + 1}` : index + 1}s.png`}
                            alt={slide.title}
                          />
                        </div>
                        {/*<h1>{slide.title}</h1>*/}
                        <button
                            className="event_btn land_btn"
                            onClick={() => (window.location.href = slide.link!)}
                            // onClick={() => {alert('실행!!!');}}
                        >
                          <img src={slide.buttonImgPath} alt="Next" />
                        </button>
                      </div>
                    </div>
                    {/*<div className={`modal-footer ${slide.footerClass}`}>*/}
                    {/*  <p>{slide.footerText}</p>*/}
                    {/*  <button*/}
                    {/*    className="event_btn"*/}
                    {/*    onClick={() => (window.location.href = slide.link!)}*/}
                    {/*  >*/}
                    {/*    <img src={slide.buttonImg} alt="Next" />*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>
                ))}
              </div>
              <div className="slide-navigation">
                <button className="prev-slide" onClick={prevSlide}>
                  <img src="/image/navi_before.png" alt="Previous Slide" />
                </button>
                <button className="next-slide" onClick={nextSlide}>
                  <img src="/image/navi_next.png" alt="Next Slide" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionLand;
