import React from 'react';
import '../css/components/Modal.css';


interface JoinModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const JoinModal: React.FC<JoinModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="joinModal modal fade show" tabIndex={-1} role="dialog" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content min-h-auto p30">
            <button className="modal-close-btn w-ba none_bg" onClick={onClose}></button>
            <div className="modal-panel w-100">
              <div className="modal-title w-ba">
                <span className="title font-black">회원가입</span>
              </div>
              <div className="modal-body">
                {/* Join Form Fields */}
                <div className="form-group">
                  <input type="text" placeholder="아이디" name="mb_login_id_signup" />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="이름" name="mb_name1" />
                </div>
                <div className="form-group">
                  <input type="password" placeholder="비밀번호" name="password" />
                </div>
                <div className="form-group">
                  <input type="password" placeholder="비밀번호확인" name="password_confirm" />
                </div>
                {/* Footer Buttons */}
                <div className="form-footer">
                  <button type="submit" className="walbtn">회원가입</button>
                  <button type="reset" className="walbtn" onClick={onClose}>취소</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinModal;
