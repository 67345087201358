import React, {useState, useEffect, useContext, useRef} from 'react';
import HeaderSub from '../components/HeaderSub';
import '../App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useNavigate, useParams} from 'react-router-dom';
import {ApiContext} from '../App';
import Section05 from "../components/Section05";
import {useWallet} from "@aptos-labs/wallet-adapter-react";

interface ProductData {
    seqNo: number;
    productName: string;
    category: string;
    description: string;
    content: string;
    botContent: string;
    retailPrice: number;
    soldPrice: number;
    soldUsdPrice: number;
    productStock: number;
    imageFilePath: string;
    subImg1Path?: string;
    subImg2Path?: string;
    subImg3Path?: string;
    subImg4Path?: string;
    subImg5Path?: string;
}

// window 객체 확장을 위해 선언
declare global {
    interface Window {
        KCP_Pay_Execute_Web?: (form: HTMLFormElement) => void;
    }
}

const ProductBuy = () => {
    const apiUrl = useContext(ApiContext);
    const {seqNo} = useParams<{ seqNo: string }>();
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const {account} = useWallet();

    const [location, setLocation] = useState(null); // 위치 정보
    const [isDomestic, setIsDomestic] = useState(false); // 국내 여부
    useEffect(() => {
        // 위치 정보 가져오기
        const fetchLocation = async () => {
            try {

                const userLanguage = navigator.language;
                console.log(userLanguage); // 예: 'en-US', 'ko-KR'
                if (userLanguage === "ko-KR") {
                    console.log('KR!!!!!!!!');
                    setIsDomestic(true);
                } else {
                    console.log('NO!!!!!!!!');
                    setIsDomestic(false);
                }

                // const response0 = await fetch('https://api.ipify.org?format=json');
                // const data0 = await response0.json();
                //
                // console.log(data0);
                // //https://ipapi.co/8.8.8.8/json/
                // // // const response = await fetch("http://ip-api.com/json/");
                // const response = await fetch(`https://ipapi.co/${data0?.ip}/json/`);
                // const data = await response.json();
                // console.log(data);
                // setLocation(data);
                //
                // // 국가 코드가 KR(대한민국)인지 확인
                // if (data.country_code === "KR") {
                //     setIsDomestic(true);
                // } else {
                //     setIsDomestic(false);
                // }
            } catch (error) {
                console.error("위치 정보를 가져오는 데 실패했습니다:", error);
            }
        };

        fetchLocation();
    }, []);

    useEffect(() => {
        // KCP 스크립트 동적 로드
        const script = document.createElement("script");
        script.src = "https://testspay.kcp.co.kr/plugin/kcp_spay_hub.js";
        script.type = "text/javascript";
        document.body.appendChild(script);

        return () => {
            // 스크립트 제거
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/product/detail/${seqNo}`);
                const result = await response.json();


                if (result.code === 200) {
                    setProductData(result.data);
                    setTotalPrice(!isDomestic ? result.data.soldUsdPrice : result.data.soldPrice);
                } else {
                    console.error('Error fetching product:', result.message);
                }
            } catch (error) {
                console.error('Failed to fetch product data:', error);
            }
        };

        fetchProductData();
    }, [seqNo, apiUrl]);

    useEffect(() => {
        if (productData) {
            setTotalPrice(productData.soldPrice * quantity);
        }
    }, [quantity, productData]);

    const increment = () => {
        if(quantity === 15){
            alert('The maximum number is limited to 15.');
            return false;
        }
        setQuantity(quantity + 1)
    };
    const decrement = () => quantity > 1 && setQuantity(quantity - 1);

    const handleWheel = (event: React.WheelEvent) => {
        if (containerRef.current) {
            containerRef.current.scrollTop += event.deltaY;
            console.log("Wheel event triggered:", event.deltaY);  // 디버깅용 콘솔 로그
        }
    };

    const movePayForm = (title:string, amount:number, product_seq:string, quantity:number) => {
        const address = account?.address;

        if(address){
            navigate('/pages/Payment', {
                // props로 받는 list 스테이트를 넘겨준다.
                state: {title : title, amount : amount, product_seq : product_seq, quantity: quantity},
            })
        }else{
            alert('You can purchase after logging in.');
        }
    };

    if (!productData) {
        return <div>Loading...</div>;
    }

    return (

        <div
            className="store-view"
            ref={containerRef}
            style={{
                // overflowY: 'auto'
                height: '100vh'}}
            onWheel={handleWheel} // onWheel 이벤트 핸들러 설정
        >
            <HeaderSub/>
            <section id="store_view">
                <div className="merch-section">
                    <div className="container">
                        <div className="w51 fl mg0">
                            <div className="small_img3">
                                <img src="/image/left_ice.png" className="fl" alt="left ice"/>
                                <img src="/image/right_ice.png" className="fl" alt="right ice"/>
                            </div>
                            <div className="big_img_area">
                                <Swiper
                                    pagination={{clickable: true}}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {[productData.imageFilePath, productData.subImg1Path, productData.subImg2Path, productData.subImg3Path, productData.subImg4Path, productData.subImg5Path]
                                        .filter((img) => img)
                                        .map((img, index) => (
                                            <SwiperSlide key={index}>
                                                <img src={img} className="big_img" alt={`product ${index + 1}`}/>
                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                            </div>
                        </div>

                        <div className="w50 fr width">
                            <div className="box fontS" style={{fontSize:"60px"}}>
                                <div dangerouslySetInnerHTML={{__html: productData.productName}}/>
                            </div>
                            <div className="box height">
                                <div dangerouslySetInnerHTML={{__html: productData.content}}/>
                            </div>
                            <div className="box mgTop">
                                <div className="price_box">
                                    <div className="price fr">
                                        <span>{totalPrice.toLocaleString()} {!isDomestic ? 'USD':'KRW'}</span>
                                    </div>
                                    <div className="packNum fl">
                                        <button onClick={decrement} className="hoverable" id="decrement"
                                                style={{backgroundColor: '#fff', border: 0}}
                                        >
                                            <img src="/image/minus.png" alt="decrement"/>
                                        </button>
                                        <span>{quantity}</span>
                                        <button onClick={increment} className="hoverable" id="increment"
                                                style={{backgroundColor: '#fff', border: 0}}
                                        >
                                            <img src="/image/plus.png" alt="increment"/>
                                        </button>
                                    </div>
                                </div>
                                <button className="exchain_btn" onClick={() => {
                                    if (seqNo != null) {
                                        movePayForm(productData.productName, totalPrice, seqNo, quantity);
                                    }
                                }}>
                                    <img src="/image/exchain_btn.png"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'resize-container'} dangerouslySetInnerHTML={{__html: productData.botContent}}/>
                </div>
            </section>

            <Section05/>
        </div>
    );
};

export default ProductBuy;
