import React, {useEffect, useState} from 'react';
// import {Swiper, SwiperSlide} from 'swiper/react';
import {ApiContext} from '../App';

interface Section01Props {
    onBackgroundChange: (imageUrl: string) => void;
}


interface ButtonData {
    id: number;
    image: string;
    background: string;
    isActive: boolean;
}

const Section01: React.FC<Section01Props> = () => {
    const apiUrl = React.useContext(ApiContext);
    const [buttonsData, setButtonsData] = useState<ButtonData[]>([
        { id: 1, image: "/image/new_btn_ico02.png",background: "" ,isActive: true},
        { id: 2, image: "/image/new_btn_ico01.png",background: "" ,isActive: false},
        { id: 3, image: "/image/new_btn_ico01.png",background: "" ,isActive: false},
        { id: 4, image: "/image/new_btn_ico01.png",background: "" ,isActive: false},
        { id: 5, image: "/image/new_btn_ico01.png",background: "" ,isActive: false},
    ]);

    const [defaultBackground, setDefaultBackground] = useState<string>("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const fetchBackgroundImages = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/main/thumbnail/pc`);
                const result = await response.json();

                if (result.code === 200 && result.data) {
                    const newButtonsData = [
                        { id: 1, image: "/image/new_btn_ico02.png",background: result.data.introDetailImage1 ,isActive: true},
                        { id: 2, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage2 ,isActive: false},
                        { id: 3, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage3 ,isActive: false},
                        { id: 4, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage4 ,isActive: false},
                        { id: 5, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage5 ,isActive: false},
                    ];
                    setButtonsData(newButtonsData);

                    // 첫 번째 이미지를 기본 배경으로 설정
                    setDefaultBackground(result.data.introDetailImage1);

                    // 이미지 미리 로드
                    preloadImages(newButtonsData.map((button) => button.background));
                } else {
                    console.error('Failed to fetch background images:', result.message);
                }
            } catch (error) {
                console.error('Error fetching background images:', error);
            }
        };

        // fetchBackgroundImages();
    }, [apiUrl]);

    useEffect(() => {
        const handleResize = () => {
            const isNowMobile = window.innerWidth <= 780;

            if (isNowMobile !== isMobile) {
                // 모바일/PC 상태가 변경될 때 API 호출
                setIsMobile(isNowMobile);
            }
        };

        const callApi = async ({deviceType}: { deviceType: any }) => {
            try {
                const endpoint = deviceType === 'Mobile'
                    ? `${apiUrl}/api/main/thumbnail/mobile`
                    : `${apiUrl}/api/main/thumbnail/pc`;

                const response = await fetch(endpoint);

                if (!response.ok) {
                    console.error('Network error:', response.statusText);
                    return;
                }

                const result = await response.json();

                if (result.code === 200 && result.data) {
                    const newButtonsData = [
                        { id: 1, image: "/image/new_btn_ico02.png",background: result.data.introDetailImage1 ,isActive: true},
                        { id: 2, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage2 ,isActive: false},
                        { id: 3, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage3 ,isActive: false},
                        { id: 4, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage4 ,isActive: false},
                        { id: 5, image: "/image/new_btn_ico01.png",background: result.data.introDetailImage5 ,isActive: false},
                    ];
                    setButtonsData(newButtonsData);

                    // 첫 번째 이미지를 기본 배경으로 설정
                    setDefaultBackground(result.data.introDetailImage1);

                    // 이미지 미리 로드
                    preloadImages(newButtonsData.map((button) => button.background));
                } else {
                    console.error('Failed to fetch background images:', result.message);
                }

            } catch (error) {
                console.error('Error fetching thumbnail images:', error);
            }
        };

        // 초기 상태에 따라 API 호출
        callApi({deviceType: isMobile ? "Mobile" : "PC"});

        window.addEventListener("resize", handleResize);

        // cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);


    const preloadImages = (imageUrls: string[]) => {
        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    };

    const handleClick = (id: number, background: string) => {
        // 클릭된 버튼을 활성화하고, 이미지 변경
        const updatedButtonsData = buttonsData.map((button) => {
            if (button.id === id) {
                return { ...button, isActive: true, image: "/image/new_btn_ico02.png" }; // 활성화된 버튼 이미지 변경
            } else {
                return { ...button, isActive: false, image: "/image/new_btn_ico01.png" }; // 나머지 버튼은 기본 이미지
            }
        });
        setButtonsData(updatedButtonsData);
        setDefaultBackground(background)
        document.body.style.backgroundColor = background;
    };

    return (
        <section
            id="prologue"
            style={{
                backgroundImage: `url(${defaultBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        >
            <article className="apart"><br/><br/><br/><br/></article>
            <article className="bpart">
                {buttonsData.map((button) => (
                    <button
                        key={button.id}
                        type="button"
                        onClick={() => handleClick(button.id, button.background)}
                        className={button.isActive ? 'active' : ''}// 활성화된 버튼에 클래스 추가
                    >
                        <img src={button.image} alt={`Button ${button.id}`}/>
                    </button>
                ))}
            </article>
        </section>
    );
};

export default Section01;
