import React, {useContext, useEffect, useState} from 'react';
import '../payment.css';
import '../css/pages/MyPage.css';
import {ApiContext} from "../App";
import {useWallet} from "@aptos-labs/wallet-adapter-react";

interface DataItem {
    index: number;
    seqNo: number;
    productName: string;
    amount: number;
    quantity: string;
    regDate: string;
    recvName: string;
    recvPhone: string;
    recvEmail: string;
    recvAddress: string;
    status: string;
}

const PurchaseHistory: React.FC = () => {
    const apiUrl = useContext(ApiContext);
    const [totalCount, setTotalCount] = useState(0); // 현재
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [list, setList] = useState<DataItem[]>([]); // 데이터 리스트 (배열로 초기화)
    const [error, setError] = useState<string | null>(null); // Error state
    const rowsPerPage = 5; // 페이지당 표시할 데이터 개수
    const storedAccount = localStorage.getItem('@aptos-connect/connectedAccount');
    const {account} = useWallet();

    useEffect(() => {

        console.log(storedAccount);
        fetchData();
    }, [apiUrl, currentPage, account?.address]); // Add apiUrl to dependency array

    const fetchData = async () => {
        try {

            console.log('123444');
            const response = await fetch(`${apiUrl}/api/payment/pay-list?pageNum=${currentPage}&itemCount=5&walletAddr=${account?.address}`);
            const result = await response.json();
            console.log('result: ', result);
            // Ensure the result is an object with a 'data' property containing an array
            if (result && Array.isArray(result.data[0]?.getPaymentListResponse)) {

                const dataWithIndex = result.data[0]?.getPaymentListResponse.map((item: any, index: number) => ({
                    ...item,
                    index: index + 1, // Adding 1 to the index to make it human-readable (starting from 1)
                }));

                console.log('dataWithIndex: ', dataWithIndex);
                setTotalCount(result.data[0]?.totalCount);
                setList(dataWithIndex);
            } else {
                setError('Invalid data format received.');
                console.error('Expected an object with a "data" array but got:', result);
            }

        } catch (error) {
            setError('Error fetching data. Please try again later.');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        console.log('Updated list:', list);
    }, [list]);

    // 데이터 페이징
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = list.slice(indexOfFirstRow, indexOfLastRow);

    // 총 페이지 계산
    const totalPages = Math.ceil(totalCount / rowsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const PayCancel = async (seqNo: any) => {
        //     취소요청 api (POST)
        // 결제완료(SUCCESS) -> 취소요청(CANCEL_REQUESTED) 상태값 변경 api
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("seqNo", seqNo);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        // @ts-ignore
        fetch("https://api.l-and.shop/api/payment/cancel-request", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                fetchData();
            })
            .catch((error) => console.error(error));
    };

    const PayCancel2 = async (seqNo: any) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("seqNo", seqNo);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        // @ts-ignore
        fetch("https://api.l-and.shop/api/payment/cancel-revert", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                fetchData();
            })
            .catch((error) => console.error(error));
    };

    console.log(currentRows);
    return (

        <div className="mypageModal modal fade show" role="dialog" style={{paddingRight: '17px;'}} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content min-h-auto ">
                    {/*<button data-dismiss="modal" className="modal-close-btn w-ba none_bg"></button>*/}
                    <div className="modal-panel w-100">
                        <div className="modal-body mt-md-4 mb-4 mt-2">
                            <div className="text-left mb-4">
                                <h4 className="mb-0 font-bold">Purchase history</h4>
                            </div>
                            <div className="form-container"
                                 style={{width: '100%', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                <table id="mypageList">
                                    <thead>
                                    <tr style={{height: '50px'}}>
                                        <th>Number</th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Purchase Date</th>
                                        <th>Recipient</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>status</th>
                                    </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                    {list.length > 0 ? (
                                        list.map((row, index) => (
                                        <tr key={index}> {/* Use r, ow.id instead of index for a unique key */}
                                            <td style={styles.td}>{row.index}</td>
                                            <td style={styles.td}>{row.productName}</td>
                                            <td style={styles.td}>{row.amount?.toLocaleString()}</td>
                                            <td style={styles.td}>{row.quantity}</td>
                                            <td style={styles.td}>{row.regDate}</td>
                                            <td style={styles.td}>{row.recvName}</td>
                                            <td style={styles.td}>{row.recvPhone}</td>
                                            <td style={styles.td}>{row.recvEmail}</td>
                                            <td style={styles.td}>{row.recvAddress}</td>
                                            <td style={styles.td}>
                                                {row.status === 'SUCCESS' ? (
                                                    <button className="clean-button" onClick={() => {
                                                        PayCancel(row.seqNo);
                                                    }}>Cancel</button>
                                                ) : row.status === 'PENDING' ? (
                                                    `Confirm`
                                                ) : row.status === 'CANCEL_REQUESTED' ? (
                                                    <button className="clean-button" onClick={() => {
                                                        PayCancel2(row.seqNo);
                                                    }}>Applying for cancellation</button>
                                                ) : (
                                                    row.status
                                                )}
                                            </td>
                                        </tr>
                                        ))) : <></>}
                                    </tbody>
                                </table>
                                {/* 페이징 버튼 */}
                                <div style={styles.pagination}>
                                    {Array.from({length: totalPages}, (_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handlePageChange(index + 1)}
                                            style={{
                                                ...styles.pageButton,
                                                backgroundColor: currentPage === index + 1 ? '#007bff' : '#f4f4f4',
                                                color: currentPage === index + 1 ? '#fff' : '#000',
                                            }}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div>
        //     {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message if present */}
        //
        //     <table style={styles.table}>
        //         <thead>
        //         <tr>
        //             <th style={styles.th}>Number</th>
        //             <th style={styles.th}>Product name</th>
        //             <th style={styles.th}>Amount</th>
        //             <th style={styles.th}>Quantity</th>
        //             <th style={styles.th}>Date of purchase</th>
        //             <th style={styles.th}>Name</th>
        //             <th style={styles.th}>Phone Number</th>
        //             <th style={styles.th}>E-mail</th>
        //             <th style={styles.th}>Address</th>
        //             <th style={styles.th}>State</th>
        //         </tr>
        //         </thead>
        //         <tbody>
        //         {currentRows.map((row) => (
        //             <tr key={row.index}> {/* Use row.id instead of index for a unique key */}
        //                 <td style={styles.td}>{row.index}</td>
        //                 <td style={styles.td}>{row.productName}</td>
        //                 <td style={styles.td}>{row.amount.toLocaleString()}</td>
        //                 <td style={styles.td}>{row.quantity}</td>
        //                 <td style={styles.td}>{row.regDate}</td>
        //                 <td style={styles.td}>{row.recvName}</td>
        //                 <td style={styles.td}>{row.recvPhone}</td>
        //                 <td style={styles.td}>{row.recvEmail}</td>
        //                 <td style={styles.td}>{row.recvAddress}</td>
        //                 <td style={styles.td}>
        //                     {row.status === 'SUCCESS' ? (
        //                         <button onClick={() => { PayCancel(row.seqNo); }}>Cancel</button>
        //                     ) : row.status === 'PENDING' ? (
        //                         `Confirm`
        //                     ) : row.status === 'CANCEL_REQUESTED' ? (
        //                         <button onClick={() => { PayCancel2(row.seqNo); }}>Applying for cancellation</button>
        //                     ) : (
        //                         row.status
        //                     )}
        //                 </td>
        //             </tr>
        //         ))}
        //         </tbody>
        //     </table>
        //
        //     {/* 페이징 버튼 */}
        //     <div style={styles.pagination}>
        //         {Array.from({ length: totalPages }, (_, index) => (
        //             <button
        //                 key={index}
        //                 onClick={() => handlePageChange(index + 1)}
        //                 style={{
        //                     ...styles.pageButton,
        //                     backgroundColor: currentPage === index + 1 ? '#007bff' : '#f4f4f4',
        //                     color: currentPage === index + 1 ? '#fff' : '#000',
        //                 }}
        //             >
        //                 {index + 1}
        //             </button>
        //         ))}
        //     </div>
        // </div>
    );
};

const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse' as const,
        margin: '20px 0',
    },
    th: {
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#f4f4f4',
        textAlign: 'left' as const,
        color: '#000',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
        color: '#000',
    },
    pagination: {
        marginTop: '10px',
        textAlign: 'center' as const,
    },
    pageButton: {
        margin: '0 5px',
        padding: '8px 12px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
    },
};

export default PurchaseHistory;
