// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지를 섹션 크기에 맞게 조정 */
  z-index: -1; /* 배경 이미지가 콘텐츠 뒤로 가도록 설정 */
}

.modal-btn{
  width:'125px'; 
  height:'58px'; 
  border-radius:'15px';
  border:'1.5px';
  background-color:'#477DFD'
}`, "",{"version":3,"sources":["webpack://./src/css/components/Section04.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,sBAAsB;EACzC,WAAW,EAAE,0BAA0B;AACzC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,oBAAoB;EACpB,cAAc;EACd;AACF","sourcesContent":[".section-container {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100vh;\r\n  overflow: hidden;\r\n}\r\n\r\n.background-img {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover; /* 이미지를 섹션 크기에 맞게 조정 */\r\n  z-index: -1; /* 배경 이미지가 콘텐츠 뒤로 가도록 설정 */\r\n}\r\n\r\n.modal-btn{\r\n  width:'125px'; \r\n  height:'58px'; \r\n  border-radius:'15px';\r\n  border:'1.5px';\r\n  background-color:'#477DFD'\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
