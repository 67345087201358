import React, { useState } from 'react';
import Header from '../components/HeaderSub';
import Section05 from '../components/Section05';
import '../css/pages/Merge.css';

const Merge: React.FC = () => {
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const handleCheckboxChange = (index: number) => {
    setCheckedItems(prev =>
      prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]
    );
  };

  return (
    <div className="project_bg">
      <Header />
      <section id="project">
        <div className="merge-section">
          <div className="container">
            <div className="search_box">
              <input placeholder="Search..." />
              <button><img src="/image/zoom.png" alt="Search" /></button>
            </div>
            <h2>If you check the item, the MERGE button will be activated!</h2>
            <div className="merge-panel dflex-ac-jc">
              {Array.from({ length: 10 }).map((_, index) => (
                <a href="#" key={index} className="merge-btn">
                  <div className="g-panel w-ba">
                    <input
                      type="checkbox"
                      checked={checkedItems.includes(index)}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <img className="g-img" src={`/image/pudgy_0${index + 1}.png`} alt={`Pudgy Penguin ${index + 1}`} />
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="merge_code">
            <button
              className={`merge_btn ${checkedItems.length > 0 ? 'active' : 'disabled'}`}
              disabled={checkedItems.length === 0}
            >
              MERGE
            </button>
          </div>
        </div>
      </section>
      <Section05/>
    </div>
  );
};

export default Merge;

// 빈 export 구문 추가
export {};
