import React, {useContext, useEffect, useState} from 'react';
import '../payment.css';
import {useNavigate} from "react-router-dom";

const PaymentFail: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="p-bg">
            <div className="payment">
                <div className="p-wrap fail">
                    <img src="/image/fail.svg" alt="fail"/>
                    <p>Payment Failed</p>
                    <p>We can't proccess your payment.Check your internet connection and try again.</p>
                    {/*<button>Try Again</button>*/}
                    <button onClick={() => {
                        navigate("/")
                    }}>Back to Main
                    </button>

                </div>
            </div>
        </div>
    );
};

export default PaymentFail;

// 빈 export 구문 추가
export {};
