import React, {useContext, useEffect, useState} from 'react';
import '../payment.css';
import {useNavigate} from "react-router-dom";

interface PrivacyProps {
    closeModal: () => void;
}
const Privacy: React.FC<PrivacyProps> = ({ closeModal }) => {
    const navigate = useNavigate();
    return (
        <div className="privacyPolicyModal closeM modal fade" role="dialog" aria-modal="true"
             >
            <div role="document">
                <div className="modal-content min-h-auto">
                    <button type="button" className="m-close-btn" data-modal="privacyPolicyModal" id="closePrivacyButton"  onClick={()=> {closeModal();}}>
                        <img src="/image/close_modal.png"/></button>
                    <div className="modal-panel w-100">
                        <div className="modal-body mt-md-4 mb-4 mt-2">
                            <div className="text-left mb-4">
                                <h4 className="mb-0 font-bold ">Privacy Policy</h4>
                            </div>
                            <div className="form-container scrollable" style={{fontFamily: 'math'}}>
                                <p>
                                    <p>In accordance with Article 30 of the Personal Information Protection Act,</p>
                                    <p>Manzo Lab establishes and discloses the following privacy policy to protect the
                                        personal information of the information subject and to promptly and smoothly
                                        handle any grievances related thereto.</p>
                                    <p>This privacy policy will be applied from December 23, 2024.</p>
                                </p>

                                <p>Article 1 Purpose of Processing Personal Information</p>
                                <p>Manzo Lab processes personal information for the following purposes. The personal
                                    information processed will not be used for any purpose other than the following
                                    purposes, and if the purpose of use changes, Manzo Lab will take necessary measures
                                    such as obtaining separate consent in accordance with Article 18 of the Personal
                                    Information Protection Act.</p>

                                <p>A. Provision of goods or services</p>
                                <p>Personal information is processed for the purpose of identification and delivery of
                                    goods/services when purchasing goods sold through the services on the LAND SHOP.</p>

                                <p>b. Provision of priority purchase service</p>
                                <p>When acquiring priority purchase rights for products sold on the services within the
                                    LAND SHOP, personal information is processed for the purpose of identification,
                                    provision of priority purchase services, and communication.</p>


                                <p>Article 2 Processing and Retention Period of Personal Information</p>
                                <p>1. Manzo Lab shall process and retain personal information within the period of
                                    retention and use of personal information in accordance with the laws and
                                    regulations or the period of retention and use of personal information agreed upon
                                    when collecting personal information from the information subject.</p>
                                <p>2. The processing and retention period of personal information is as follows:</p>
                                <p>a. When providing goods or services</p>
                                <p>b. When providing priority purchase services</p>
                                <p>c. When personal information becomes unnecessary, such as the expiration of the
                                    personal information retention period or the fulfillment of the purpose of
                                    processing, we will destroy the personal information without delay.</p>
                                <p>d. Grounds for retention: consent of the data subject</p>
                                <p>e. If personal information must be kept for a certain period of time in accordance
                                    with laws and regulations, the personal information will be kept separately in
                                    accordance with the provisions of the laws and regulations during that period.</p>
                                <p>f. Applicable laws: Records on consumer complaints or dispute handling for 3
                                    years</p>


                                <p>Article 3 Consignment of personal information processing</p>
                                <p>1. Manzo Lab consigns personal information processing as follows for smooth personal
                                    information processing.</p>
                                <p>a. Consignor name: Manzo Lab / 02-511-4560</p>
                                <p>b. Contents of consignment: Sending text messages for delivery of goods, delivery of
                                    notices</p>
                                <p>c. Consignment period: Destroyed without delay upon fulfillment of purpose</p>
                                <p>2. In accordance with Article 26 of the Personal Information Protection Act, Manzo
                                    Lab specifies in documents such as contracts the prohibition of processing personal
                                    information other than for the purpose of performing consignment work, technical and
                                    administrative protection measures, restrictions on re-consignment, management and
                                    supervision of the consignee, and liability for damages, and supervises whether the
                                    consignee handles personal information safely.</p>
                                <p>3. If the contents of the consignment work or the consignee change, we will disclose
                                    it through this privacy policy without delay.</p>


                                <p>Article 4 Rights and obligations of the information subject and legal representative
                                    and how to exercise them</p>
                                <p>1. The information subject may exercise the right to view, correct, delete, or
                                    request suspension of processing of personal information at any time against Manzo
                                    Lab.</p>
                                <p>2. The exercise of the rights under Paragraph 1 may be made in writing, e-mail,
                                    facsimile transmission (FAX), etc. to Manzo Lab in accordance with Article 41 (1) of
                                    the Enforcement Decree of the 「Personal Information Protection Act」, and Manzo Lab
                                    will take action without delay.</p>
                                <p>3. The exercise of rights under Paragraph 1 may be made through an agent, such as the
                                    legal representative of the information subject or a person who has been delegated.
                                    In this case, you must submit a power of attorney in the form of Attachment No. 11
                                    of the “Notification on the Method of Processing Personal Information (No.
                                    2020-7)”.</p>
                                <p>4. The rights of the information subject may be restricted in accordance with Article
                                    35 (4) and Article 37 (2) of the Personal Information Protection Act when requesting
                                    to view and suspend the processing of personal information.</p>
                                <p>5. A request for correction and deletion of personal information cannot be made if
                                    the personal information is specified as the subject of collection in other laws and
                                    regulations.</p>
                                <p>6. Manzo Lab shall verify whether the person making a request for access, correction,
                                    deletion, or suspension of processing in accordance with the rights of the
                                    information subject is the person or a legitimate representative.</p>

                                <p>Article 5 Items of Personal Information Processed</p>
                                <p>1. Manzo Lab processes the following personal information items for identification,
                                    delivery of goods/services, and communication when providing goods or services.</p>
                                <p>a. Required items: Name, email, phone number, address and zip code, Aptos wallet
                                    address</p>


                                <p>Article 6 Destruction of Personal Information</p>
                                <p>1. Manzo Lab shall destroy the personal information without delay when the personal
                                    information becomes unnecessary, such as the expiration of the personal information
                                    retention period or the achievement of the purpose of processing.</p>
                                <p>2. If the personal information retention period agreed to by the information subject
                                    has elapsed or the purpose of processing has been achieved, but the personal
                                    information must continue to be preserved in accordance with other laws and
                                    regulations, the personal information shall be transferred to a separate database
                                    (DB) or preserved in a different storage location.</p>
                                <p>a. Legal basis: 3 years for records of consumer complaints or dispute handling</p>
                                <p>b. Items of personal information we retain: Name, email, phone number, address and
                                    zip code, Aptos wallet address</p>
                                <p>3. The procedures and methods for destroying personal information are as follows.</p>
                                <p>a. Destruction procedure: Manzo Lab selects the personal information that has caused
                                    the reason for destruction and destroys the personal information with the approval
                                    of the personal information protection officer of Manzo Lab.</p>
                                <p>b. Destruction method: Information in the form of electronic files is destroyed using
                                    a technical method that cannot reproduce the record. Personal information printed on
                                    paper is destroyed by shredding or incineration.</p>


                                <p>Article 7 Measures to secure the safety of personal information</p>
                                <p>Manzo Lab takes the following measures to ensure the safety of personal
                                    information.</p>
                                <p>1. Minimization and training of employees handling personal information</p>
                                <p>a. We take measures to minimize personal information by designating and limiting
                                    employees who handle personal information to those in charge.</p>
                                <p>2. Technical measures against hacking, etc.</p>
                                <p>a. To prevent leakage and damage of personal information due to hacking or computer
                                    viruses, Manzo Lab installs security programs, periodically updates and inspects
                                    them, installs systems in areas with controlled access from the outside, and
                                    monitors and blocks them technically and physically.</p>
                                <p>3. Restriction of access to personal information</p>
                                <p>a. Necessary measures are taken to control access to personal information by
                                    granting, changing, or canceling access rights to the database system that processes
                                    personal information, and unauthorized access from the outside is controlled using
                                    an intrusion prevention system.</p>
                                <p>4. Control access to unauthorized persons</p>
                                <p>a. We have a separate physical storage location for personal information and have
                                    established and operated access control procedures for it.</p>


                                <p>Article 8 Installation, Operation, and Rejection of Automatic Personal Information
                                    Collection Devices</p>
                                <p>Manzo Lab does not use 'cookies' that store and retrieve the usage information of the
                                    information subject from time to time.</p>


                                <p>Article 9 Personal Information Protection Officer</p>
                                <p>1. Manzo Lab is responsible for the overall handling of personal information, and
                                    designates the person in charge of personal information protection as follows to
                                    handle complaints and damage relief of information subjects related to the handling
                                    of personal information.</p>
                                <p>Personal Information Protection Officer<br/>
                                    Name: Wonjae Cho<br/>
                                    Position: Personal Information Manager<br/>
                                    Contact : zzam219@naver.com</p>
                                <p>2. The information subject may inquire about all personal information protection
                                    related inquiries, complaints, damage relief, etc. arising from the use of Manzo
                                    Lab's LAND SHOP service to the personal information protection officer and the
                                    department in charge. Manzo Lab will respond to and handle the inquiries of the
                                    information subject without delay.</p>

                                <p>Article 10 Request for access to personal information</p>
                                <p>The information subject may request access to personal information pursuant to
                                    Article 35 of the Personal Information Protection Act to the following departments.
                                    Manzo Lab will endeavor to promptly process the information subject's request for
                                    access to personal information.</p>

                                <p>Article 11 Remedies for infringement of rights and interests</p>
                                <p>The information subject may apply for dispute resolution or consultation to the
                                    Personal Information Dispute Mediation Committee, Korea Internet & Security Agency's
                                    Personal Information Infringement Report Center, etc. in order to receive relief due
                                    to personal information infringement. In addition, please contact the following
                                    organizations for other personal information infringement reports and
                                    consultations.</p>

                                <p>a. Personal Information Dispute Mediation Committee: (without area code) 1833-6972
                                    (www.kopico.go.kr)</p>
                                <p>b. Personal Information Infringement Report Center: (without area code) 118
                                    (privacy.kisa.or.kr)</p>
                                <p>c. Supreme Prosecutors' Office: (without area code) 1301 (http://www.spo.go.kr)</p>
                                <p>d. National Police Agency: (without area code) 182 (http://ecrm.cyber.go.kr)</p>

                                <p>A person whose rights or interests have been infringed upon by a decision or omission
                                    made by the head of a public institution in response to a request under Articles 35
                                    (Access to Personal Information), 36 (Correction and Deletion of Personal
                                    Information), or 37 (Suspension of Processing of Personal Information) of the
                                    Personal Information Protection Act may file an administrative appeal as provided in
                                    the Administrative Appeals Act.</p>
                                <p>※ For more information on administrative appeals, please refer to the website of the
                                    Central Administrative Appeals Commission (http://www.simpan.go.kr).</p>

                                <p>Article 12 Privacy Policy Changes</p>
                                <p>1. This Privacy Policy is effective as of December 23, 2024.</p>
                                <p>2. If the Privacy Policy changes in the future, Manzo Lab will post the changes on
                                    the Privacy Policy page on the homepage.</p>


                                <div className="form-footer flex-wrap mt-4">
                                    <button type="button"
                                            className="walbtn w-30"
                                            id="closeTermsButton"
                                            style={{borderRadius: '30px'}}
                                            onClick={() => {
                                                closeModal();
                                            }}
                                    >Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;

// 빈 export 구문 추가
export {};
