import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import Header from '../components/HeaderSub';
import '../App.css';

interface AddressProps {
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    setRegion: React.Dispatch<React.SetStateAction<string>>;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    setDeliveryFee: React.Dispatch<React.SetStateAction<number>>;
    country: string;
    quantity: string;
    isDomestic: boolean;
}

// countries 배열의 각 항목 타입 정의
interface Country {
    code: string;
    name: string;
    iso2: string;
}

interface StateList {
    id: string;
    name: string;
    iso2: string;
}

interface CityList {
    id: string;
    name: string;
    iso2: string;
}

const Address: React.FC<AddressProps> = ({setCountry, setRegion, setCity,setDeliveryFee, country, quantity, isDomestic}) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [states, setStates] = useState<StateList[]>([]);
    const [cityse, setCityse] = useState<CityList[]>([]);
    const [iso2, setIso2] = useState('');
    const [count, setCount] = useState('');
    const [siso, setSiso] = useState('');
    const [cityId, setCityId] = useState('');

    const data = [
        { name: "USA", parcel_1: 34, parcel_2: 54, parcel_3: 73, parcel_4: 93, parcel_5: 112, parcel_6: 132, parcel_7: 151, parcel_8: 170, parcel_9: 189, parcel_10: 209, parcel_11: 228, parcel_12: 248, parcel_13: 267, parcel_14: 287, parcel_15: 306 },
        { name: "Canada", parcel_1: 30, parcel_2: 41, parcel_3: 52, parcel_4: 64, parcel_5: 76, parcel_6: 88, parcel_7: 100, parcel_8: 112, parcel_9: 124, parcel_10: 136, parcel_11: 148, parcel_12: 160, parcel_13: 172, parcel_14: 184, parcel_15: 196 },
        { name: "Germany/UK", parcel_1: 32, parcel_2: 42, parcel_3: 52, parcel_4: 64, parcel_5: 76, parcel_6: 88, parcel_7: 100, parcel_8: 112, parcel_9: 124, parcel_10: 136, parcel_11: 148, parcel_12: 160, parcel_13: 172, parcel_14: 184, parcel_15: 195 },
        { name: "Singapore/Taiwan", parcel_1: 18, parcel_2: 24, parcel_3: 31, parcel_4: 37, parcel_5: 44, parcel_6: 51, parcel_7: 57, parcel_8: 64, parcel_9: 70, parcel_10: 77, parcel_11: 84, parcel_12: 90, parcel_13: 97, parcel_14: 104, parcel_15: 110 },
        { name: "Japan/China", parcel_1: 24, parcel_2: 29, parcel_3: 34, parcel_4: 39, parcel_5: 43, parcel_6: 48, parcel_7: 53, parcel_8: 57, parcel_9: 62, parcel_10: 66, parcel_11: 70, parcel_12: 74, parcel_13: 79, parcel_14: 83, parcel_15: 87 },
        { name: "Malaysia", parcel_1: 19, parcel_2: 27, parcel_3: 34, parcel_4: 41, parcel_5: 49, parcel_6: 58, parcel_7: 66, parcel_8: 74, parcel_9: 83, parcel_10: 91, parcel_11: 99, parcel_12: 107, parcel_13: 115, parcel_14: 124, parcel_15: 132 },
        { name: "Thailand", parcel_1: 18, parcel_2: 24, parcel_3: 29, parcel_4: 35, parcel_5: 42, parcel_6: 49, parcel_7: 55, parcel_8: 62, parcel_9: 69, parcel_10: 76, parcel_11: 83, parcel_12: 90, parcel_13: 97, parcel_14: 103, parcel_15: 110 },
        { name: "HongKong/Philippines", parcel_1: 21, parcel_2: 25, parcel_3: 29, parcel_4: 33, parcel_5: 39, parcel_6: 45, parcel_7: 50, parcel_8: 56, parcel_9: 62, parcel_10: 67, parcel_11: 73, parcel_12: 79, parcel_13: 84, parcel_14: 90, parcel_15: 95 },
        { name: "New Zealand/France", parcel_1: 27, parcel_2: 39, parcel_3: 51, parcel_4: 64, parcel_5: 76, parcel_6: 89, parcel_7: 101, parcel_8: 114, parcel_9: 126, parcel_10: 139, parcel_11: 151, parcel_12: 164, parcel_13: 176, parcel_14: 188, parcel_15: 201 },
        { name: "Australia/Spain", parcel_1: 30, parcel_2: 44, parcel_3: 58, parcel_4: 71, parcel_5: 85, parcel_6: 99, parcel_7: 112, parcel_8: 126, parcel_9: 140, parcel_10: 153, parcel_11: 167, parcel_12: 181, parcel_13: 194, parcel_14: 208, parcel_15: 222 },
        { name: "Brazil", parcel_1: 37, parcel_2: 53, parcel_3: 74, parcel_4: 98, parcel_5: 120, parcel_6: 143, parcel_7: 166, parcel_8: 189, parcel_9: 212, parcel_10: 235, parcel_11: 258, parcel_12: 281, parcel_13: 304, parcel_14: 327, parcel_15: 350 },
        { name: "India", parcel_1: 22, parcel_2: 29, parcel_3: 37, parcel_4: 44, parcel_5: 58, parcel_6: 72, parcel_7: 85, parcel_8: 99, parcel_9: 113, parcel_10: 127, parcel_11: 140, parcel_12: 154, parcel_13: 168, parcel_14: 182, parcel_15: 195 },
        { name: "Austria", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223 }
    ];

    const list =
        [
            {name: "Australia", parcel_1: 30, parcel_2: 44, parcel_3: 58, parcel_4: 71, parcel_5: 85, parcel_6: 99, parcel_7: 112, parcel_8: 126, parcel_9: 140, parcel_10: 153, parcel_11: 167, parcel_12: 181, parcel_13: 194, parcel_14: 208, parcel_15: 222},
            {name: "Austria", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Belgium", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Brazil", parcel_1: 37, parcel_2: 53, parcel_3: 74, parcel_4: 98, parcel_5: 120, parcel_6: 143, parcel_7: 166, parcel_8: 189, parcel_9: 212, parcel_10: 235, parcel_11: 258, parcel_12: 281, parcel_13: 304, parcel_14: 327, parcel_15: 350},
            {name: "Canada", parcel_1: 30, parcel_2: 41, parcel_3: 52, parcel_4: 64, parcel_5: 76, parcel_6: 88, parcel_7: 100, parcel_8: 112, parcel_9: 124, parcel_10: 136, parcel_11: 148, parcel_12: 160, parcel_13: 172, parcel_14: 184, parcel_15: 196 },
            {name: "China", parcel_1: 24, parcel_2: 29, parcel_3: 34, parcel_4: 39, parcel_5: 43, parcel_6: 48, parcel_7: 53, parcel_8: 57, parcel_9: 62, parcel_10: 66, parcel_11: 70, parcel_12: 74, parcel_13: 79, parcel_14: 83, parcel_15: 87},
            {name: "Czech Republic", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Denmark", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Finland", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "France", parcel_1: 27, parcel_2: 39, parcel_3: 51, parcel_4: 64, parcel_5: 76, parcel_6: 89, parcel_7: 101, parcel_8: 114, parcel_9: 126, parcel_10: 139, parcel_11: 151, parcel_12: 164, parcel_13: 176, parcel_14: 188, parcel_15: 201},
            {name: "Germany", parcel_1: 32, parcel_2: 42, parcel_3: 52, parcel_4: 64, parcel_5: 76, parcel_6: 88, parcel_7: 100, parcel_8: 112, parcel_9: 124, parcel_10: 136, parcel_11: 148, parcel_12: 160, parcel_13: 172, parcel_14: 184, parcel_15: 195},
            {name: "Hong Kong S.A.R.", parcel_1: 21, parcel_2: 25, parcel_3: 29, parcel_4: 33, parcel_5: 39, parcel_6: 45, parcel_7: 50, parcel_8: 56, parcel_9: 62, parcel_10: 67, parcel_11: 73, parcel_12: 79, parcel_13: 84, parcel_14: 90, parcel_15: 95},
            {name: "Hungary", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "India", parcel_1: 22, parcel_2: 29, parcel_3: 37, parcel_4: 44, parcel_5: 58, parcel_6: 72, parcel_7: 85, parcel_8: 99, parcel_9: 113, parcel_10: 127, parcel_11: 140, parcel_12: 154, parcel_13: 168, parcel_14: 182, parcel_15: 195},
            {name: "Ireland", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Israel", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Italy", parcel_1: 30, parcel_2: 44, parcel_3: 58, parcel_4: 71, parcel_5: 85, parcel_6: 99, parcel_7: 112, parcel_8: 126, parcel_9: 140, parcel_10: 153, parcel_11: 167, parcel_12: 181, parcel_13: 194, parcel_14: 208, parcel_15: 222 },
            {name: "Taiwan", parcel_1: 30, parcel_2: 44, parcel_3: 58, parcel_4: 71, parcel_5: 85, parcel_6: 99, parcel_7: 112, parcel_8: 126, parcel_9: 140, parcel_10: 153, parcel_11: 167, parcel_12: 181, parcel_13: 194, parcel_14: 208, parcel_15: 222 },
            {name: "Japan", parcel_1: 24, parcel_2: 29, parcel_3: 34, parcel_4: 39, parcel_5: 43, parcel_6: 48, parcel_7: 53, parcel_8: 57, parcel_9: 62, parcel_10: 66, parcel_11: 70, parcel_12: 74, parcel_13: 79, parcel_14: 83, parcel_15: 87},
            {name: "Malaysia", parcel_1: 19, parcel_2: 27, parcel_3: 34, parcel_4: 41, parcel_5: 49, parcel_6: 58, parcel_7: 66, parcel_8: 74, parcel_9: 83, parcel_10: 91, parcel_11: 99, parcel_12: 107, parcel_13: 115, parcel_14: 124, parcel_15: 132},
            {name: "Netherlands", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "New Zealand", parcel_1: 27, parcel_2: 39, parcel_3: 51, parcel_4: 64, parcel_5: 76, parcel_6: 89, parcel_7: 101, parcel_8: 114, parcel_9: 126, parcel_10: 139, parcel_11: 151, parcel_12: 164, parcel_13: 176, parcel_14: 188, parcel_15: 201},
            {name: "Norway", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Philippines", parcel_1: 21, parcel_2: 25, parcel_3: 29, parcel_4: 33, parcel_5: 39, parcel_6: 45, parcel_7: 50, parcel_8: 56, parcel_9: 62, parcel_10: 67, parcel_11: 73, parcel_12: 79, parcel_13: 84, parcel_14: 90, parcel_15: 95},
            {name: "Poland", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Portugal", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Singapore", parcel_1: 18, parcel_2: 24, parcel_3: 31, parcel_4: 37, parcel_5: 44, parcel_6: 51, parcel_7: 57, parcel_8: 64, parcel_9: 70, parcel_10: 77, parcel_11: 84, parcel_12: 90, parcel_13: 97, parcel_14: 104, parcel_15: 110},
            {name: "South Korea", parcel_1: 3500, parcel_2: 7000, parcel_3: 10500, parcel_4: 14000, parcel_5: 17500, parcel_6: 21000, parcel_7: 24500, parcel_8: 28000, parcel_9: 31500, parcel_10: 70000, parcel_11: 38500, parcel_12: 42000, parcel_13: 45500, parcel_14: 49000, parcel_15: 52500},
            {name: "Spain", parcel_1: 30, parcel_2: 44, parcel_3: 58, parcel_4: 71, parcel_5: 85, parcel_6: 99, parcel_7: 112, parcel_8: 126, parcel_9: 140, parcel_10: 153, parcel_11: 167, parcel_12: 181, parcel_13: 194, parcel_14: 208, parcel_15: 222 },
            {name: "Sweden", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Switzerland", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "Thailand", parcel_1: 18, parcel_2: 24, parcel_3: 29, parcel_4: 35, parcel_5: 42, parcel_6: 49, parcel_7: 55, parcel_8: 62, parcel_9: 69, parcel_10: 76, parcel_11: 83, parcel_12: 90, parcel_13: 97, parcel_14: 103, parcel_15: 110},
            {name: "United Arab Emirates", parcel_1: 31, parcel_2: 42, parcel_3: 53, parcel_4: 67, parcel_5: 81, parcel_6: 95, parcel_7: 109, parcel_8: 123, parcel_9: 138, parcel_10: 152, parcel_11: 166, parcel_12: 180, parcel_13: 194, parcel_14: 208, parcel_15: 223},
            {name: "United Kingdom", parcel_1: 32, parcel_2: 42, parcel_3: 52, parcel_4: 64, parcel_5: 76, parcel_6: 88, parcel_7: 100, parcel_8: 112, parcel_9: 124, parcel_10: 136, parcel_11: 148, parcel_12: 160, parcel_13: 172, parcel_14: 184, parcel_15: 195},
            {name: "United States", parcel_1: 34, parcel_2: 54, parcel_3: 73, parcel_4: 93, parcel_5: 112, parcel_6: 132, parcel_7: 151, parcel_8: 170, parcel_9: 189, parcel_10: 209, parcel_11: 228, parcel_12: 248, parcel_13: 267, parcel_14: 287, parcel_15: 306}
        ];

    function getParcelValueByCountry(list: any[], country: any, quantity: any) {
        // parcel 키 생성
        if(country === 'Italy'){
            return null;
        }
        const parcelKey = `parcel_${quantity}`;

        // 해당 나라의 데이터를 찾음
        const countryData = list.find(item => item.name === country);

        // 데이터가 존재하고 해당 parcel 값이 있으면 반환
        if (countryData && countryData[parcelKey] !== undefined) {
            return countryData[parcelKey];
        }

        // 데이터가 없거나 parcel 값이 없으면 null 반환
        return null;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        const array1: Country[] = res;
                        const array2 = list;
                        const commonItems = isDomestic ? array1.filter(item1 =>
                            array2.some(item2 => item2.name === item1.name)
                        ) : array1.filter(item1 =>
                            item1.name === 'South Korea'
                        ) ;
                        setCountries(commonItems);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if(country){
            const price = getParcelValueByCountry(list, country, quantity);
            setDeliveryFee(price);
            console.log(price);
        }

    }, [country]);

    useEffect(() => {
        const getState = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        setStates(res);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        if (iso2) getState();
    }, [iso2]);

    useEffect(() => {
        const getCity = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch(`https://api.countrystatecity.in/v1/countries/${iso2}/states/${siso}/cities`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        setCityse(res);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        if (siso) getCity();
    }, [siso]);

    const onChange1 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setIso2(value);
        // iso2와 일치하는 국가를 찾기
        const matchingValues = countries.filter(country => country.iso2 === value);

        if (matchingValues.length > 0) {
            setCountry(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setCountry('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    const onChange2 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setSiso(value);

        const matchingValues = states.filter(StateList => StateList.iso2 === value);
        if (matchingValues.length > 0) {
            setRegion(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setRegion('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    const onChange3 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setCityId(value);
        const matchingValues = cityse.filter(CityList => CityList.id == value);
        if (matchingValues.length > 0) {
            setCity(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setCity('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    function CountrySelect() {
        return (
            <select className="select-box" value={iso2} onChange={onChange1}>
                <option value={''}>Select a country</option>
                {countries.map((item, index) => (
                    <option key={index} value={item.iso2} id={item.name}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }

    function StateSelect() {
        return (
            <select className="select-box" value={siso} onChange={onChange2} disabled={states.length > 0 ? false : true} >
                <option value={''}>Select a state</option>
                {states.map((item, index) => (
                    <option key={index} value={item.iso2} id={item.name}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }

    function CitySelect() {
        return (
            <select className="select-box" value={cityId} onChange={onChange3} disabled={cityse.length > 0 ? false : true}>
                <option value={''}>Select a city</option>
                {cityse.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }


    return (
        <div className="select-div">
            <CountrySelect/>
            <StateSelect/>
            <CitySelect/>

        </div>
    );
};

export default Address;
