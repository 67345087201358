import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/HeaderSub';
import Section05 from '../components/Section05';
import '../App.css';

interface EventData {
  seqNo: number;
  title: string;
  content: string;
}

const EventDetail: React.FC = () => {
  const { seqNo } = useParams<{ seqNo: string }>();
  const [eventData, setEventData] = useState<EventData | null>(null);
  const containerRef = useRef<HTMLDivElement>(null); // container 요소 참조 생성

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`https://api.l-and.shop/api/event/detail/${seqNo}`);
        const result = await response.json();

        if (result.code === 200) {
          setEventData(result.data);
        } else {
          console.error('Error fetching event data:', result.message);
        }
      } catch (error) {
        console.error('Failed to fetch event data:', error);
      }
    };

    fetchEventData();
  }, [seqNo]);

  // 마우스 휠 스크롤 이벤트 추가
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (containerRef.current) {
        containerRef.current.scrollTop += event.deltaY;
      }
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      // wheel 이벤트 리스너를 추가합니다.
      containerElement.addEventListener('wheel', handleWheel);

      // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
      return () => {
        containerElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  return (
    <div className="event-detail" ref={containerRef} style={{ overflow: 'auto', height: '100vh' }}>
      <Header />
      {/* <div className="wallet">
        <button className="event-link">
          <img src="/image/wallet.png" alt="Wallet" />
        </button>
      </div> */}

      {/* Belly Blue Image Section */}
      <div className="belly-B">
        <div className="belly-blue">
          {/* Event Details */}
          <div className="event-details">
            {eventData ? (
              <>
                <div dangerouslySetInnerHTML={{ __html: eventData.content }} />
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
        <div className="belly-blue2"></div>
      </div>

      {/* Footer */}
      <Section05/>
    </div>
  );
};

export default EventDetail;
